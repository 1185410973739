import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import {Chart as ChartJS, registerables} from "chart.js";
import {Bar} from "react-chartjs-2";

import SendinBlueStatsService from "../utils/SendinBlueStatsService";
import SoanLoader from "../components/SoanLoader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faFilter} from "@fortawesome/free-solid-svg-icons";

ChartJS.register(...registerables);
const DashboardPage = props => {
    const date = new Date();
    const [startDate, setStartDate] = useState(
        `${date.getMonth() + 1}-${date.getFullYear()-1}`
    );
    const [endDate, setEndDate] = useState(
        `${date.getMonth()}-${date.getFullYear()}`
    );
    const [isLoading, setIsLoading] = useState(false);
    const [stats, setStats] = useState(null);

    const ACCEPTED_LABELS = [
        'Requests',
        'Delivered',
        'Hard Bounces',
        'Soft Bounces',
        'Clicks',
        'Opens',
    ];

    useEffect(() => {
        setIsLoading(true);
        if(stats){
            setStats(null)
        }
        SendinBlueStatsService.getStatsByMonth(startDate, endDate)
            .then((stats) => {
                setIsLoading(false);
                const labels = []
                const datasets = [
                    { label: 'Requests', data: [], backgroundColor: '#4caf50' },          // Green
                    { label: 'Delivered', data: [], backgroundColor: '#ff9800' },         // Orange
                    { label: 'Hard Bounces', data: [], backgroundColor: '#f44336' },      // Red
                    { label: 'Soft Bounces', data: [], backgroundColor: '#3f51b5' },      // Indigo
                    { label: 'Clicks', data: [], backgroundColor: '#9c27b0' },            // Purple
                    { label: 'Unique Clicks', data: [], backgroundColor: '#e91e63' },     // Pink
                    { label: 'Opens', data: [], backgroundColor: '#2196f3' },             // Blue
                    { label: 'Unique Opens', data: [], backgroundColor: '#00bcd4' },      // Cyan
                    { label: 'Spam Reports', data: [], backgroundColor: '#607d8b' },      // Blue Grey
                    { label: 'Blocked', data: [], backgroundColor: '#9e9e9e' },            // Grey
                    { label: 'Invalid', data: [], backgroundColor: '#795548' },            // Brown
                    { label: 'Unsubscribed', data: [], backgroundColor: '#ff5722' }        // Deep Orange
                ];

                stats.forEach((stat) => {
                    labels.push(Object.keys(stat)[0]);
                    const values = Object.values(stat)[0];
                    Object.keys(values).forEach((key, index) => {
                        datasets[index].data.push(values[key]);
                    });
                });

                setStats({
                    labels: labels,
                    datasets: datasets
                });

            })
            .catch((err) => {
                console.log(err);
            })
    }, []);

    return (
        <div className="mt-32">
            <div className="flex justify-between mb-5">
                <p className="font-bold text-2xl poppins-font mb-5">Statistiques</p>
                {
                    /*
                    <div>
                        <button className="bg-white rounded border shadow hover:shadow-lg py-2 px-4 mr-5">
                            <span className="text-lg mr-2">Filtrer les données</span>
                            <FontAwesomeIcon icon={faFilter} />
                        </button>
                        <button className="bg-white rounded border shadow hover:shadow-lg py-2 px-4">
                            <span className="text-lg mr-2">Exporter</span>
                            <FontAwesomeIcon icon={faDownload} />
                        </button>
                    </div>
                     */
                }
            </div>
            <div className="flex flex-col bg-white p-4 rounded shadow justify-center items-center">
                <SoanLoader show={isLoading} size='extra-small'/>
                {
                    stats && (
                        <div className="h-[45vh] w-full">
                            <Bar
                                data={{
                                    labels: stats.labels,
                                    datasets: stats.datasets
                                }}
                                options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                        x: {
                                            stacked: true,
                                        },
                                        y: {
                                            stacked: true
                                        }
                                    }
                                }}
                            />
                        </div>
                    )
                }
            </div>
            <div className="mt-4 text-gray-700">
                <p>Besoin d'aide ?</p>
                <p>
                    <Link
                        to="https://www.notion.so/Interface-mailing-08b31071d4c54a6bb01b94785615e0df"
                        target="_blank"
                        className="underline">
                        Consulter le guide
                    </Link>
                </p>
            </div>
        </div>
    );
}
export default DashboardPage;
