import React, {useContext, useEffect, useState} from "react";
import {Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";
import "../assets/css/main.css";

import { AuthContext } from "../contexts/AuthContext";
import Header from "../components/dashboard/Header";
import SoanLoader from "../components/SoanLoader";

const DashboardLayout = (props) => {
    const {pathname} = useLocation();
    const {isLoggedIn, user, tokenExpDate, logout } = useContext(AuthContext);
    const [tokenExpired, setTokenExpired] = useState(
        tokenExpDate ? tokenExpDate < Date.now() / 1000 : false
    );
    useEffect(() => {
        if(isLoggedIn && !pathname.startsWith('/login')){
            const nowDate = new Date();
            const diff = tokenExpDate - nowDate.getTime() / 1000;

            if(diff < 0){
                setTokenExpired(true);
                logout();
            }
        }
    }, [pathname]);

    if(isLoggedIn === undefined){
        return <Navigate to="/login" />;
    }

    return isLoggedIn && !tokenExpired ?
        <div className="container mx-auto max-w-screen-xl">
            <Header user={user} logout={logout}/>
            <Outlet />
        </div> :
        <Navigate to="/login" state={{ tokenExpired }}/>;
}

export default DashboardLayout;