import Modal, {ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition} from "@atlaskit/modal-dialog";
const CustomModalDialog = ({ isOpen, onCancel, onConfirm , headerTitle, bodyContent }) => {
    return isOpen && (
        <ModalTransition>
            <Modal>
                <ModalHeader>
                    <ModalTitle>
                        <h1 className="poppins-font">{headerTitle}</h1>
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    {bodyContent}
                </ModalBody>
                <ModalFooter>

                    <button
                        className="bg-gray-400 text-white rounded px-4 py-2 mr-2"
                        onClick={() => { onCancel() }}>Annuler</button>

                    <button
                        className="bg-red-500 text-white rounded px-4 py-2"
                        onClick={() => { onConfirm() }}>Supprimer</button>
                </ModalFooter>
            </Modal>
        </ModalTransition>
    )
}

CustomModalDialog.defaultProps = {
    isOpen: false,
    headerTitle: 'Modal Title',
    bodyContent: 'Modal Body Content',
};

export default CustomModalDialog;
