import {createContext, useEffect, useState} from 'react';
import APIService from "../utils/APIService";
import {getTokenExtraInfo} from "../utils/utils";

export const AuthContext = createContext(null);

const api = new APIService();
export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(undefined);
    const [token, setToken] = useState(null);
    const [tokenExpDate, setTokenExpDate] = useState(null);
    const [error, setError] = useState(false);
    const [contextLoaded, setContextLoaded] = useState(false);

    useEffect(() => {
        const localToken = window.localStorage.getItem("token") ?? null;
        if(localToken){
            const {iat, exp, roles, username} = getTokenExtraInfo(localToken);

            if(exp > Date.now() / 1000){
                setToken(localToken);
                setTokenExpDate(exp);

                api.withToken(localToken).get('/me')
                    .then(res => res.json())
                    .then(res => {
                        if(res?.id){
                            setUser(res);
                            setIsLoggedIn(true);
                        }
                    })
                    .catch(err => {
                        setIsLoggedIn(false);
                        setError(true);
                    })
            }else{
                setIsLoggedIn(false);
            }
        }
        setContextLoaded(true);
    }, [])


    const login = (username, password) => {
        api.post('/login', {username, password})
            .then(res => res.json())
            .then(res => {
                if(res?.token){
                    const {iat, exp, roles, username} = getTokenExtraInfo(res.token);
                    if(exp > Date.now() / 1000){
                        window.localStorage.setItem("token", res.token);
                        setToken(res.token);
                        setTokenExpDate(exp);
                        api.withToken(res.token).get('/me')
                            .then(res => res.json())
                            .then(res => {
                                if(res?.id){
                                    setUser(res);
                                    setIsLoggedIn(true)
                                }
                            })
                            .catch(err => {
                                setIsLoggedIn(false);
                                setError(true);
                            })
                    }
                }else{
                    setError(true);
                }
            })
            .catch(err => {
                setError(true)
            })
    }

    const logout = () => {
        setUser(null);
        setIsLoggedIn(false);
        setToken(null);
        window.localStorage.removeItem("token");
    };

    const cancelError = () => setError(false)
    const authContextValue = {
        user,
        isLoggedIn,
        token,
        tokenExpDate,
        login,
        logout,
        error,
        cancelError
    }

    return (
        <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>
    )
};

