import React, {Fragment, useContext, useEffect, useState} from "react";
import APIService from "../../utils/APIService";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAngleUp, faArrowDown, faArrowDownAZ, faArrowDownZA, faArrowUp, faArrowUpAZ, faArrowUpZA, faEye,
    faPen,
    faPlus,
    faSearch, faSort, faSortAsc, faSortDown, faSortUp,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import SoanLoader from "../../components/SoanLoader";
import Alert from "../../components/Alert";
import CustomModalDialog from "../../components/Modal";
import {Pagination} from "@mui/material";
import {parseDate} from "../../utils/date";
import Clamp from "react-multiline-clamp";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons/faAngleDown";
import {AuthContext} from "../../contexts/AuthContext";

const EventsPage = props => {
    const {user} = useContext(AuthContext);
    const [orderBy, setOrderBy] = useState('modifiedAt');
    const [order, setOrder] = useState('DESC');

    const [events, setEvents] = useState([]);
    const [selectedEvents, setSelectedEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [query, setQuery] = useState('');

    const api = new APIService();

    useEffect(() => {
        setLoading(true);
        api.get(`/events?page=${page}&orSearch[name]=${query}&orSearch[description]=${query}&order[${orderBy}]=${order}`)
            .then(res => res.json())
            .then(data => {
                let hydraMembers = data['hydra:member'] || [];
                setLoading(false);
                setEvents(hydraMembers);
                setTotalPages(Math.ceil((data['hydra:totalItems'] || 0) / 10));
            })
            .catch(err => console.log(err));
    }, [page, query, orderBy, order]);

    const deleteEvents = () => {
        setShowModal(false)
        for(const eventId of selectedEvents){
            api.delete(`/events/${eventId}`)
                .then(res => {
                    if(res.status === 204){
                        setEvents(events.filter(event => event.id !== eventId))
                        setSelectedEvents(selectedEvents.filter(id => id !== eventId))
                        setAlert({
                            type: 'success',
                            message: 'Événement supprimé avec succès'
                        })
                    }else if(res.status === 404){
                        setAlert({
                            type: 'error',
                            message: 'Événement introuvable'
                        })
                    }else{
                        setAlert({
                            type: 'error',
                            message: 'Une erreur est survenue lors de la suppression de l\'événement'
                        })
                    }
                })
                .catch(err => {
                    alert('erreur')
                    setAlert({
                        type: 'error',
                        message: "Une erreur est survenue lors de la suppression de l\'événement"
                    })
                });
        }
    }

    const getOrderIcon = fieldName => {
        if(fieldName !== orderBy){
            return faSort;
        }
        if(fieldName === orderBy && 'DESC' === order){
            return faArrowDownZA;
        }
        return faArrowDownAZ;
    }

    return (
        <Fragment>
            <CustomModalDialog
                isOpen={showModal}
                onCancel={() => setShowModal(false)}
                onConfirm={deleteEvents}
                headerTitle="Confirmation de la suppression"
                bodyContent="Êtes-vous sûr de vouloir supprimer les événements sélectionnés ?"/>

            <div id="events">
                <section>
                    <header>
                        <h1 className="poppins-font mb-4 text-2xl">Événements</h1>
                        <p>Cet page vous permet de voir, créer, modifier ou supprimer <br/> des événements.</p>
                    </header>
                    <div className="mt-10 flex justify-between">
                        <div className="flex h-10">
                            {
                                user?.roles.includes('ROLE_ADMIN') ? (
                                    <Link to="/events/create" className="bg-[#00E5DA] font-bold py-2 px-8 rounded">
                                        <FontAwesomeIcon icon={faPlus} className="mr-4" />
                                        <span>Ajouter un nouvel événement</span>
                                    </Link>
                                ): <div className="w-72"/>
                            }
                        </div>
                        <div className="ml-2 relative flex items-center w-32 basis-1/3">
                            <input
                                type="text"
                                placeholder="Filtrer par nom"
                                onChange={(e) => {
                                    const queryText = e.target.value;
                                    if(queryText?.trim().length > 2){
                                        setQuery(e.target.value)
                                    }else{
                                        setQuery('')
                                    }
                                }}
                                className="w-full h-full rounded shadow border pl-4 pr-9 focus:border-gray-300 focus:outline-none"/>
                            <FontAwesomeIcon
                                icon={faSearch}
                                className="absolute text-gray-500 hover:text-gray-700 hover:cursor-pointer right-4"/>
                        </div>
                        <div className="flex items-center">
                            {
                                user?.roles.includes('ROLE_ADMIN') && (
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        onClick={ () => {
                                            if(selectedEvents.length > 0){
                                                setShowModal(true)
                                            }
                                        }}
                                        className={
                                            selectedEvents?.length > 0 ? "text-black hover:cursor-pointer" : "text-gray-500"
                                        }
                                    />
                                )
                            }
                        </div>
                    </div>
                </section>
                {
                    loading ?
                        <SoanLoader show={loading} /> :
                        <section className="mt-10 flex-col">
                                <Alert type={alert?.type} message={alert?.message} show={ alert?.type !== undefined } />
                                <table className="w-full mt-10 border-separate border-spacing-y-2 table-fixed">
                                    <thead>
                                    <tr className="text-left poppins-font">
                                        <th className="w-12"></th>
                                        <th className="w-80">
                                            <span>Nom</span>
                                        </th>
                                        <th className="w-96">Description</th>
                                        <th className="w-52">
                                            <span>Date de création</span>
                                            <FontAwesomeIcon
                                                icon={getOrderIcon('createdAt')}
                                                className="ml-2 hover:cursor-pointer"
                                                onClick={() => {
                                                    setOrderBy('createdAt')
                                                    setOrder(order === 'DESC' ? 'ASC' : 'DESC')
                                                }}
                                            />
                                        </th>
                                        <th className="w-60">
                                            <span>Dernière modification</span>
                                            <FontAwesomeIcon
                                                icon={getOrderIcon('modifiedAt')}
                                                className="ml-2 hover:cursor-pointer"
                                                onClick={() => {
                                                    setOrderBy('modifiedAt')
                                                    setOrder(order === 'DESC' ? 'ASC' : 'DESC')
                                                }}
                                            />
                                        </th>
                                        <th>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {events.map(event => (
                                        <tr
                                            key={event.id}
                                            className="shadow bg-white">
                                            <td className="p-4">
                                                <input
                                                    type="checkbox"
                                                    className="form-checkbox"
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            setSelectedEvents([...selectedEvents, event.id])
                                                        } else {
                                                            setSelectedEvents(selectedEvents.filter(id => id !== event.id))
                                                        }
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <Link
                                                    className="font-bold"
                                                    to={`/events/${event.id}/edit`} state={event}> {event.name} </Link>
                                                <p className="text-xs text-gray-600">
                                                    Crée par <span className="font-bold">{ event?.createdBy?.firstName || '' }</span>
                                                </p>
                                            </td>
                                            <td>
                                                <div className="flex items-center pr-4">
                                                    <Clamp
                                                        showLines={2}
                                                        withToggle={true}
                                                        showMoreElement={({toggle}) => (
                                                            <FontAwesomeIcon
                                                                icon={faAngleDown}
                                                                onClick={toggle}
                                                                className="text-gray-400 hover:text-black hover:cursor-pointer" />
                                                        )}
                                                        showLessElement={({toggle}) => (
                                                            <FontAwesomeIcon
                                                                icon={faAngleUp}
                                                                onClick={toggle}
                                                                className="text-gray-400 hover:text-black hover:cursor-pointer" />
                                                        )}
                                                    >
                                                        <span>{event.description ?? 'No description'}</span>
                                                    </Clamp>
                                                </div>
                                            </td>
                                            <td>{ parseDate(event.createdAt) ?? '-' }</td>
                                            <td>{ parseDate(event.modifiedAt) ?? '-' }</td>
                                            <td className="">
                                                <Link
                                                    to={`/events/${event.id}/edit`}
                                                    state={event}>
                                                    <FontAwesomeIcon icon={
                                                        (user?.roles.includes('ROLE_ADMIN') || user?.roles.includes('ROLE_EDITOR')) ? faPen : faEye
                                                    } className="text-gray-500 hover:text-gray-700 hover:cursor-pointer"/>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                <div className="my-10 flex justify-center">
                                    <Pagination
                                        count={totalPages}
                                        shape="rounded"
                                        variant="outlined"
                                        className="mt-10"
                                        onChange={(e, page) => setPage(page)}/>
                                </div>
                            </section>
                }


            </div>
        </Fragment>
    )
}

export default EventsPage;