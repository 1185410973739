
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {getHighestRole, ROLES, ROLES_COLOR} from "../../utils/utils";


const UsersList = ({users, selectedUsers, toggleSelectUser, selectAll}) => {
    const list = users.map((user, index) => {
        return (
            <tr key={index} className="rounded-md bg-white pt-5 mb-2 shadow">
                <td className="py-4 px-2 flex justify-center">
                    <input
                        type="checkbox"
                        checked={selectedUsers.includes(user.id)}
                        onChange={() => toggleSelectUser(user.id) }
                    />
                </td>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.email}</td>
                <td>
                    <span
                        className={`py-1 px-3 bg-[${ROLES_COLOR[getHighestRole(user.roles)]}] rounded-3xl`}>
                        { ROLES[getHighestRole(user.roles)] }
                    </span>
                </td>
                <td>
                    <Link to={`users/${user.id}/edit`}>
                        <FontAwesomeIcon
                            icon={faPen}
                            className="text-gray-400 hover:text-black hover:cursor-pointer"/>
                    </Link>
                </td>
            </tr>
        )
    });

    return (
        <div className="mt-6">
            <table id="users-list" className="w-full border-separate border-spacing-y-2">
                <thead className="text-left">
                    <tr>
                        <th className="py-4 px-2 flex justify-center">
                            <input type="checkbox" onChange={() => selectAll() } />
                        </th>
                        <th>Prénom</th>
                        <th>Nom</th>
                        <th>Adresse e-mail</th>
                        <th>Rang</th>
                    </tr>
                </thead>
                <tbody>
                    { list }
                </tbody>
            </table>
        </div>
    )
}

export default UsersList;