import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {useContext, useState} from "react";
import {faArrowLeft, faBackward, faPlus, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import APIService from "../../utils/APIService";
import Alert from "../../components/Alert";
import {AuthContext} from "../../contexts/AuthContext";

const EventEditPage = () => {
    const { user } = useContext(AuthContext);
    const { eventId } = useParams();
    const location = useLocation();
    const event = location?.state
    const [newEvent, setNewEvent] = useState(event ?? {});
    const [variables, setVariables] = useState(location.state?.datas || [])
    const [alert, setAlert] = useState(null);

    const api = new APIService()

    const updateVariable = (index, content) => {
        const newVariables = [...variables];
        newVariables[index] = content;
        setVariables(newVariables);
    }

    const saveEvent = () => {
        const data = {
            name: newEvent.name,
            description: newEvent.description,
            datas: variables.filter(variable => variable !== '')
        }

        if(event?.id){
            api.patch(`/events/${eventId}`, data)
                .then(response => response.json())
                .then(response => {
                    setAlert({
                        type: 'success',
                        message: "L'événement a été mis à jour avec succès"
                    })
                })
                .catch(err => {
                    console.error(err)
                    setAlert({
                        type: 'error',
                        message: "Erreur !, Nous n'avons pas pu mettre à jour l'événement"
                    })
                });
        }else{
            api.post('/events', data)
                .then(response => response.json())
                .then(response => {
                    setAlert({
                        type: 'success',
                        message: "L'événement a été créé avec succès"
                    })
                })
                .catch(err => {
                    console.error(err)
                    setAlert({
                        type: 'error',
                        message: "Erreur !, Nous n'avons pas pu créer l'événement"
                    })
                });
        }

    }

    return (
        <div id="event-edit-page">
            <section className="flex justify-center mb-16">
                <header className="flex lg:w-2/3 items-center justify-between">
                    <Link to="/events">
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            className="hover:cursor-pointer"/>
                    </Link>
                    <div>
                        <h1 className="font-bold text-2xl poppins-font mb-5">Gestion d'événement</h1>
                        <p className="text-gray-500">Modifier les informations de l'événement</p>
                    </div>
                </header>
            </section>
            <div className="flex flex-col items-center justify-center">
                <div className="w-1/2 mb-4">
                    <Alert type={alert?.type} message={alert?.message} show={alert != null} />
                </div>
                <div className="w-1/2 bg-white py-8 px-6 rounded-md shadow-lg">
                    <div className="mb-4">
                        <label htmlFor="name" className="block text-gray-700 text-sm font-bold poppins-font mb-2">Nom</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Nom de l'événement"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={newEvent.name}
                            onChange={e => setNewEvent({ ...newEvent, name: e.target.value })}
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="description" className="block text-gray-700 text-sm font-bold mb-2 poppins-font">Description</label>
                        <textarea
                            id="description"
                            name="description"
                            placeholder="Description de l'événement"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={newEvent.description}
                            onChange={e => setNewEvent({ ...newEvent, description: e.target.value })}
                        />
                    </div>
                    <div className="mb-8">
                        <p className="block text-gray-700 text-sm font-bold mb-2 poppins-font">Variables</p>
                        <div className="bg-gray-50 p-2 border rounded flex-col items-center p-4">
                            <div id="variables" className="flex flex-col items-center">
                            {
                                variables.map((variable, index) => (
                                    <div key={index} className="flex justify-between items-center w-full mb-4">
                                        <input
                                            type="text"
                                            key={index}
                                            className="w-full shadow bg-white px-4 py-1"
                                            placeholder="Clé json de la variable"
                                            value={variable}
                                            disabled={!user?.roles?.includes('ROLE_ADMIN')}
                                            onChange={ e => updateVariable(index, e.target.value) }/>

                                        {
                                            (user?.roles?.includes('ROLE_ADMIN') || user?.roles?.includes('ROLE_EDITOR')) && (
                                                <FontAwesomeIcon
                                                    icon={faXmark}
                                                    onClick={ e => setVariables(variables.filter((_, i) => i !== index))}
                                                    className="ml-2 text-red-500 cursor-pointer"/>
                                            )
                                        }
                                    </div>
                                ))
                            }
                            </div>
                            {
                                (user?.roles?.includes('ROLE_ADMIN') || user?.roles?.includes('ROLE_EDITOR')) && (
                                    <button
                                        onClick={e => setVariables([...variables, '']) }
                                        className="bg-[#00E5DA] text-xs font-bold py-2 px-4 rounded">
                                        <FontAwesomeIcon icon={faPlus} className="mr-4" />
                                        <span>Ajouter une variable</span>
                                    </button>
                                )
                            }
                        </div>
                    </div>
                    {
                        (user?.roles?.includes('ROLE_ADMIN') || user?.roles?.includes('ROLE_EDITOR')) && (
                            <button
                                type="submit"
                                onClick={(e) => saveEvent() }
                                className="bg-[#00E5DA] font-bold py-2 px-8 rounded">
                                {
                                    event ? <span>Modifier l'événement</span> : <span>Ajouter un nouvel événement</span>
                                }
                            </button>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default EventEditPage;