import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {Link, useNavigate, useParams} from "react-router-dom";
import UserForm from "../components/form/UserForm";
import APIService from "../utils/APIService";
import React, {Fragment, useEffect, useState} from "react";

const UserInfoPage = props => {

    const api = new APIService()
    const [user, setUser] = useState({})
    const navigation = useNavigate();
    const { userId } = useParams();
    const [alert, setAlert] = useState(null);

    useEffect(() => {
        if(userId){
            api.get(`/users/${userId}`)
                .then(res => res.json())
                .then(data => {
                    setUser(data)
                })
                .catch(err => console.error(err))
        }

    }, []);

    const saveUserInfo = (userInfo, saveAndCreate = false) => {
        const data = {
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            email: userInfo.email,
            roles:  [userInfo.role]
        }

        if(!userId) {
            api.post('/users', { ...data, password: userInfo.password })
                .then(res => {
                    if (res?.status === 201) {
                        if (saveAndCreate) {
                            setUser({});
                            setAlert({
                                type: 'success',
                                message: `Le compte de ${data.firstName} a été créé avec succès`
                            });
                        } else {
                            navigation('/admin', {
                                state: {
                                    alert: {type: 'success', message: `Le compte de ${data.firstName} a été créé avec succès`}
                                }
                            });
                        }
                    } else {
                        setAlert({type: 'error', message: `La tentative de création d'un compte a échoué.`});
                    }

                    return res.json();
                })
                .then(res => {
                    // scroll to the top of the page when the form is submitted
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });

                })
                .catch(err => {
                    setAlert({type: 'error', message: `La tentative de création d'un compte a échoué.`});
                    console.error(err)
                })
        }
        else {
            api.patch(`/users/${userId}`, { ...data, password: userInfo.password })
                .then(res => {
                    if (res?.ok) {
                        setAlert({type: 'success', message: `Le compte de ${data.lastName} a été modifié avec succès` });
                        return res.json();
                    } else {
                        setAlert({type: 'error', message: `Une erreur s'est produite, veuillez réessayer.`});
                    }
                })
                .then(res => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                })
                .catch(err => {
                    console.error(err)
                    setAlert({type: 'error', message:  `Une erreur s'est produite, veuillez réessayer.`});
                });
        }
    }

    return (
        <div id="user-info-page" className="flex flex-col items-center my-20">
            <section className="lg:w-1/2">
                <header className="flex items-center justify-between">
                    <Link to='/admin'>
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            className="hover:cursor-pointer"/>
                    </Link>
                    <div>
                        <h1 className="font-bold text-2xl poppins-font mb-5">Gestion des utilisateurs</h1>
                        { props.pageMode === "CREATE" ?
                            <p>Cette page vous permet de créer un nouvel utilisateur.</p> :
                            <p>Cette page vous permet de gérer vos utilisateurs, de <br/>supprimer, ou de modifier les informations de leur profil.</p>
                        }
                    </div>
                </header>
            </section>
            <section className="mt-10 w-full lg:w-7/12 bg-white p-5 rounded-lg drop-shadow">
                <UserForm
                    save={ saveUserInfo }
                    user={user}
                    alertData={alert} />
            </section>
        </div>
    )
}

UserInfoPage.defaultProps = {
    pageMode: "CREATE"
}

export default UserInfoPage;