//NOTE: EXEMPLE OF USE IS IN THE FILE "Login.jsx" FROM LINE 17 TO 28
export default class SendinBlueStatsService {
    static API_KEY = "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";
    static STARTING_DATES = {
        1: '01-01',
        2: '02-01',
        3: '03-01',
        4: '04-01',
        5: '05-01',
        6: '06-01',
        7: '07-01',
        8: '08-01',
        9: '09-01',
        10: '10-01',
        11: '11-01',
        12: '12-01',
    };
    static ENDING_DATES = {
        1: '01-31',
        2: '02-28',
        3: '03-31',
        4: '04-30',
        5: '05-31',
        6: '06-30',
        7: '07-31',
        8: '08-31',
        9: '09-30',
        10: '10-31',
        11: '11-30',
        12: '12-31',
    }
    static SENDINBLUE_URL = "https://api.brevo.com/v3/smtp/statistics"

    // Get the stats grouped by month. Format expected MM-YYYY. Return an object {"MM-YYYY" : {stats}, "MM-YYYY" : {stats}}
    static getStatsByMonth = async (startingDate, endingDate, tag = null) => {
        // the variable we will return
        let statsByMonth = [];
        // Make an array of single month.
        const arrayOfSingleMonths = this.makeArrayOfGroupedMonths(startingDate, endingDate, 1);
        // loop on each month to get the stats and add it to the statsByMonth object
        for (const month of arrayOfSingleMonths) {
            // modify the format of the date if it is "1-YYYY" to "01-YYYY"
            const formattedMonth = month[0].split("-").map(part => part.padStart(2, "0")).join("-");
            // get the stats for the month and add it to the statsByMonth object
            const stat = await this.getStats(formattedMonth, formattedMonth).then(res => {
                statsByMonth.push({[formattedMonth]: res});
            });
        }
        return statsByMonth;
    }

    // format and manage datas received to get statistics from sendinblue api. Expected format MM-YYYY. Return an object {"key": "value", "key": "value"} like the const totalResult
    static async getStats(startingDate, endingDate, tag = null) {

        // startingDate and endingDate must be in format MM-YYYY
        try {
            // check if starting date or ending date are in the future
            if (this.isBackToTheFuture(startingDate, endingDate)) {
                return new Error("Impossible de récupérer les données du futur. Mais alors Doc ? Toutes ces histoires sur les risques de modifier le futur, le continuum espace-temps ?");
            }
            // check if the current month is asked
            if (this.isCurrentMonth(startingDate, endingDate)) {
                return new Error("Impossible de récupérer les données du mois en cours");
            }
            // check if the startingdate or endingdate format are good
            if (!this.isDateHaveGoodFormat(startingDate) || !this.isDateHaveGoodFormat(endingDate)) {
                return new Error("Les dates doivent être au format MM-YYYY");
            }
            // check if the endingDate is not before the startingDate
            if (this.isEndingDateBeforeStartingDate(startingDate, endingDate)) {
                return new Error("La date de fin ne peut pas être antérieure à la date de début");
            }
            // define variables needed
            const totalResult = {
                requests : 0,
                delivered : 0,
                hardBounces : 0,
                softBounces : 0,
                clicks : 0,
                uniqueClicks : 0,
                opens : 0,
                uniqueOpens : 0,
                spamReports : 0,
                blocked : 0,
                invalid : 0,
                unsubscribed : 0,
            }
            let sendinBlueUrl;
            // make an array of double month. Format expected MM-YYYY
            const arrayOfDoubleMonths = this.makeArrayOfGroupedMonths(startingDate, endingDate);
            // loop on the array of double month to make the request on each pair of month and cumulate the result
            for (const pairOfMonth of arrayOfDoubleMonths) {
                // if the pair of month is only one month, the ending index is 0, else it's 1
                const endingIndex = pairOfMonth.length === 1 ? 0 : 1;
                // make the request url
                sendinBlueUrl = this.makeRequestUrl(pairOfMonth[0], pairOfMonth[endingIndex]);

                const response = await this.callBrevoApi(sendinBlueUrl);
                for (const key in response) {
                    if (response.hasOwnProperty(key) && totalResult.hasOwnProperty(key)) {
                        totalResult[key] += response[key];
                    }
                }
            }
            return totalResult;
        }
        catch (e) {
            throw new Error(e);
        }
    }
    // make a request to the sendinblue api to get statistics in function of url. Return a promise
    static callBrevoApi = async (url) => {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'api-key': this.API_KEY,
            },
        });
        if (!response.ok) {
            throw new Error("Une erreur est survenue lors de la reception des données");
        }
        return response.json();
    }
    // remove leading zero from a string
    static removeLeadingZero = (str) => {
        return str[0] === '0' ? str.slice(1) : str;
    };
    // make a request url from starting and ending date. Format expected MM-YYYY
    static makeRequestUrl = (startingDate, endingDate, tag = null) =>{
        // get sendinblue url
        const url = `${this.SENDINBLUE_URL}/aggregatedReport`;
        // manage date format for starting date
        // const startingYear = this.removeLeadingZero(startingDate.split('-')[1]);
        // const startingMonth = this.removeLeadingZero(startingDate.split('-')[0]);
        const [startingMonth, startingYear] = startingDate.split('-').map(this.removeLeadingZero);
        // manage date format for ending date
        const [endingMonth, endingYear] = endingDate.split('-').map(this.removeLeadingZero);
        // formate date for url
        const urlStartingDate = `${startingYear}-${this.STARTING_DATES[startingMonth]}`;
        const urlEndingDate = `${endingYear}-${this.ENDING_DATES[endingMonth]}`;
        // return the url
        const queryString = tag === null ? '' : `&tag=${tag}`;
        return `${url}?startDate=${urlStartingDate}&endDate=${urlEndingDate}${queryString}`;
    }
    // make an array of months grouped by size. Format expected MM-YYYY
    static makeArrayOfGroupedMonths = (startingDate, endingDate, size = 2) => {
        const [startMonth, startYear] = startingDate.split('-').map(this.removeLeadingZero);
        const [endMonth, endYear] = endingDate.split('-').map(this.removeLeadingZero);
        // Créer les objets Date pour la date de début et la date de fin
        const startDate = new Date(`${startYear}-${startMonth}-01`);
        const endDate = new Date(`${endYear}-${endMonth}-01`);
        // Tableau pour stocker tous les mois entre la date de début et la date de fin
        const monthsArray = [];
        // Tableau final avec les paires de mois
        const finalArray = [];

        let currentDate = startDate;
        while (currentDate <= endDate) {
            // Obtenir le mois (0-indexé, donc +1)
            const month = currentDate.getMonth() + 1;
            // Obtenir l'année
            const year = currentDate.getFullYear();

            // Formater le mois et l'année sans zéro en tête
            const formattedMonth = this.removeLeadingZero(month.toString());
            const formattedYear = this.removeLeadingZero(year.toString());

            // Ajouter le mois et l'année formatés au tableau monthsArray
            monthsArray.push(`${formattedMonth}-${formattedYear}`);

            // Passer au mois suivant (on a ajouté 1 au mois à cause de l'index 0 ce qui fait que le mois suivant est déjà sélectionné)
            currentDate.setMonth(month);
        }

        // Diviser le tableau monthsArray en sous-tableaux de "size" mois consécutifs
        for (let i = 0; i < monthsArray.length; i += size) {
            finalArray.push(monthsArray.slice(i, i + size));
        }
        // Retourner le tableau final avec les paires de mois
        return finalArray;
    };
    // check if date is in good format MM-YYYY
    static isDateHaveGoodFormat = (date) => {
        const dateRegex = /^(0[1-9]|1[0-2])-\d{4}$/;
        return dateRegex.test(date);
    }
    // check if starting and ending date are the same as current month and year
    static isCurrentMonth = (startingDate, endingDate) => {
        // make a new date now
        const today = new Date();
        // get current month and year
        let currentMonth = today.getMonth() + 1;
        let currentYear = today.getFullYear();
        // format current month and year in string and delete leading zero
        currentMonth = this.removeLeadingZero(currentMonth.toString());
        currentYear = this.removeLeadingZero(currentYear.toString());
        // format starting and ending date with deleting leading zero
        startingDate = this.removeLeadingZero(startingDate);
        endingDate = this.removeLeadingZero(endingDate);
        // format current month and year in string to compare with starting and ending date
        const currentMonthYear = `${currentMonth}-${currentYear}`;
        // return true if starting and ending date are not the same as current month and year
        return startingDate === currentMonthYear || endingDate === currentMonthYear;
    }
    // check if starting or ending date are in the future
    static isBackToTheFuture = (startingDate, endingDate) => {
        // make a new date now
        const today = new Date();
        today.setHours(0, 0, 0, 0); // set time to 00:00:00 to compare only dates
        // convert input strings to date format
        const [startMonth, startYear] = startingDate.split('-');
        const [endMonth, endYear] = endingDate.split('-');
        // create date objects from starting and ending dates
        // month is 0-indexed
        const startDate = new Date(startYear, startMonth - 1, 1);
        // month is 0-indexed
        const endDate = new Date(endYear, endMonth - 1, 1);
        // compare if starting or ending date are in the future
        return startDate.getTime() > today.getTime() || endDate.getTime() > today.getTime();
    }
    // check if ending date is before starting date
    static isEndingDateBeforeStartingDate = (startingDate, endingDate) => {
        // convert input strings to date format
        const [startMonth, startYear] = startingDate.split('-');
        const [endMonth, endYear] = endingDate.split('-');
        // create date objects from starting and ending dates
        const startDate = new Date(startYear, startMonth - 1, 1); // month is 0-indexed
        const endDate = new Date(endYear, endMonth - 1, 1); // month is 0-indexed
        // compare if ending date is before starting date
        return endDate.getTime() < startDate.getTime();
    }
}