import {TextField} from "@mui/material";

const InputField = ({id, name, label, placeholder, value, onChange}) => {
    return (
        <div className="w-full mt-2 mb-5">
            <label
                className="block poppins-font mb-2"
                htmlFor={id}>{label}</label>
            <TextField
                id={id}
                name={name}
                variant="outlined"
                placeholder={placeholder}
                value={value}
                size="small"
                className="w-full outline-0"
                onChange={(e) => onChange(e)}
            />
        </div>
    )
}

InputField.defaultProps = {
    placeholder: "",
    name: "",
    value: "",
    onChange: (e) => {}
}

export default InputField;