import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import CodeEditor from "@uiw/react-textarea-code-editor";
import APIService from "../../utils/APIService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import Alert from "../../components/Alert";
import {AuthContext} from "../../contexts/AuthContext";

const BlockEditPage = () => {
    const { user } = useContext(AuthContext);
    const { blockId } = useParams();
    const [block, setBlock] = useState({
        name: '',
        body: '',
        description: '',
    });

    const [alert, setAlert] = useState(null);
    const api = new APIService();

    useEffect(() => {
        api.get(`/blocks/${blockId}`)
            .then(res => res.json())
            .then(data => {
                setBlock(data);
            })
            .catch(err => {
                console.error(err);
            })
    }, [])

    const saveBlock = () => {
        if(block?.id){
            api.patch(`/blocks/${block.id}`, {
                name: block.name,
                body: block.body,
                description: block.description,
            })
                .then(res => {
                    if(res.status === 200){
                        setAlert({
                            type: 'success',
                            message: "Block updated"
                        })
                    }else{
                        setAlert({
                            type: 'error',
                            message: "Block was saved"
                        })
                    }
                })
                .catch(err => {
                    setAlert({
                        type: 'error',
                        message: "Block was saved"
                    })
                    console.log(err)
                })
        }else{
            api.post('/blocks', {
                name: block.name,
                body: block.body,
                description: block.description,
            })
                .then(res => {
                    if(res.status === 201){
                        setAlert({
                            type: 'success',
                            message: "Block updated"
                        })
                    }else{
                        setAlert({
                            type: 'error',
                            message: "Block was saved"
                        })
                    }
                })
                .catch(err => {
                    setAlert({
                        type: 'error',
                        message: "Block was saved"
                    })
                    console.error(err);
                })
        }

    }

    return (
        <div id="block-edit">
            <section className="flex justify-center my-16">
                <header className="flex lg:w-4/5 items-center justify-between">
                    <Link to="/blocks">
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            className="hover:cursor-pointer"/>
                    </Link>
                    <div>
                        <h1 className="font-bold text-2xl poppins-font mb-5">Gestion de blocs</h1>
                        <p className="text-gray-500">Modifier/Ajouter les informations du block</p>
                    </div>
                </header>
            </section>
            <div className="flex flex-col justify-center items-center">
                <Alert type={alert?.type} message={alert?.message} show={alert?.type !== undefined } />
                <div className="mb-5 w-2/3 p-5 shadow bg-white">
                    <div className="input mb-10">
                        <label htmlFor="name" className="block mb-2 text-lg poppins-font">Nom</label>
                        <input
                            type="text"
                            id="name"
                            className="border rounded border-gray-300 shadow py-3 px-4 w-full"
                            placeholder="Nom du bloc"
                            value={block?.name || ''}
                            onChange={(ev) => {
                                setBlock({
                                    ...block,
                                    name: ev.target.value,
                                })
                            }}/>
                    </div>
                    <div className="input mb-10">
                        <label htmlFor="description" className="block mb-2 text-lg poppins-font">Description</label>
                        <textarea
                            id="description"
                            className="border rounded border-gray-300 shadow py-3 px-4 w-full"
                            placeholder="Description du bloc"
                            value={block?.description || ''}
                            onChange={(ev) => {
                                setBlock({
                                    ...block,
                                    description: ev.target.value,
                                })
                            }} />
                    </div>
                    <div className="input mb-10">
                        <label htmlFor="body" className="block mb-2 text-lg poppins-font">Contenu du bloc</label>
                        <CodeEditor
                            id="body"
                            value={block?.body || ''}
                            data-color-mode="light"
                            className="border-2 rounded shadow p-5 hover:border-gray-900"
                            padding={20}
                            language="twig"
                            placeholder="Votre code du bloc"

                            style={{
                                fontSize: 15,
                                fontFamily: 'monospace',
                                height: 500,
                                overflow: 'auto',
                            }}
                            onChange={(ev) => {
                                setBlock({
                                    ...block,
                                    body: ev.target.value,
                                })
                            }}
                        />
                    </div>
                    <div className="input">
                        {
                            (user?.roles?.includes('ROLE_ADMIN') || user?.roles?.includes('ROLE_EDITOR')) && (
                                <button
                                    onClick={() => {
                                        saveBlock()
                                    }}
                                    className="bg-[#00E5DA] font-bold py-2 px-8 rounded">
                                    <span>Enregistrer</span>
                                </button>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

BlockEditPage.defaultProps = {

}
export default BlockEditPage;
