import UserForm from "../../components/form/UserForm";
import React, {useContext, useState} from "react";
import {AuthContext} from "../../contexts/AuthContext";
import APIService from "../../utils/APIService";
import {AutoDismissFlag, FlagGroup} from "@atlaskit/flag";
import {Alert, AlertTitle} from "@mui/material";

const Account = (props) => {
    const {user} = useContext(AuthContext);
    const api = new APIService();
    const [alert, setAlert] = useState(null);

    const saveUserInfo = (userInfo, saveAndCreate = false) => {
        const data = {
            firstname: userInfo.firstName,
            lastname: userInfo.lastName,
            email: userInfo.email,
            roles:  [userInfo.role],
            password: userInfo.password
        }

        api.patch(`/users/${userInfo.id}`, data)
            .then(res => {
                if(res.status === 200){
                    setAlert({
                        type: 'success',
                        title: 'Succès',
                        message: 'Utilisateur modifié'
                    });
                }else{
                   setAlert({
                        type: 'error',
                        title: 'Erreur',
                        message: 'Une erreur est survenue'
                   });
                }
            })
            .catch(err => {
                setAlert({
                    type: 'error',
                    title: 'Erreur',
                    message: 'Une erreur est survenue'
                });
            })
    }
    return (
        <div className="flex flex-col items-center my-20">
            <section className="mt-10 w-full lg:w-7/12 bg-white p-5 rounded-lg drop-shadow">
                <UserForm
                    save={ saveUserInfo }
                    user={user}
                    alertData={alert}/>
            </section>
        </div>
    )
}

export default Account;