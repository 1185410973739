import React, {useEffect, useContext, useRef, useState, Fragment} from "react";
import {Link, useLocation} from "react-router-dom";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";

import UsersList from "../components/dashboard/UsersList";
import APIService from "../utils/APIService";
import CustomModalDialog from "../components/Modal";
import {AuthContext} from "../contexts/AuthContext";
import {Alert, AlertTitle, Pagination} from "@mui/material";
import {AutoDismissFlag, FlagGroup} from "@atlaskit/flag";
import SoanLoader from "../components/SoanLoader";

const AdminPage = (props) => {
    const {state} = useLocation();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = React.useState([]);
    const [selectedUsers, setSelectedUsers] = React.useState([]);
    const [paginationInfo, setPaginationInfo] = React.useState({
        perPage: 10,
        totalItems: 0,
        currentPage: 1,
    });
    const [showModal, setShowModal] = React.useState(false);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [query, setQuery] = useState('');
    const [flags, setFlags] = useState([]);
    const [showAlert, setShowAlert] = useState(true);

    const api = new APIService();

    useEffect(() => {
        setLoading(true);
        api.get(`/users?page=${page}&orSearch[firstName]=${query}&orSearch[lastName]=${query}&orSearch[email]=${query}`)
            .then(res => {
                if(res.status === 200) {
                    return res.json();
                }else if(res.status === 403) {
                    setFlags([...flags, {
                        title: 'Vous n\'avez pas les droits pour accéder à cette page',
                        type: 'error'
                    }]);
                    throw new Error('Forbidden');
                }else{
                    setFlags([...flags, {
                        title: 'Une erreur est survenue lors du chargement des utilisateurs',
                        type: 'error'
                    }]);
                    throw new Error('Error');
                }
            })
            .then(data => {
                let hydraMembers = data['hydra:member'] || [];
                setUsers(hydraMembers);
                setTotalPages(Math.ceil((data['hydra:totalItems'] || 0) / 10));
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            })
    }, [page, query])

    const deleteUsers = () => {
        for(const accountId of selectedUsers) {
            api.delete(`/users/${accountId}`)
                .then(res => {
                    if(res.status === 204) {
                        setUsers(users.filter(user => user.id !== accountId));
                        setSelectedUsers([]);
                        setShowModal(false);
                        setFlags([...flags, {
                            title: 'Compte supprimé avec succès',
                            type: 'success',
                        }]);
                    }else{
                        setFlags([...flags, {
                            title: 'Une erreur est survenue lors de la suppression',
                            type: 'error',
                        }]);
                    }
                })
                .catch(err => {
                    setFlags([...flags, {
                        title: 'Une erreur est survenue lors de la suppression',
                        type: 'error',
                    }]);
                });
        }
    }

    return (
       <div>
           <CustomModalDialog
               isOpen={showModal}
               onCancel={() => setShowModal(false)}
               onConfirm={deleteUsers}
               headerTitle="Confirmation de la suppression"
               bodyContent="Êtes-vous sûr de vouloir supprimer les utilisateurs sélectionnés ?"/>

           <FlagGroup onDismissed={
                (id, action) => {
                    setFlags(flags.filter((flag, index) => id !== index));
                }
           }>
               {
                   flags.map((flag, index) => (
                          <AutoDismissFlag
                                id={index}
                                key={index}
                                appearance={flag.type ?? 'info'}
                                onDismissed={() => {
                                    setFlags(flags.filter((flag, i) => index !== i));
                                }}
                                title={flag.title}/>
                   ))
               }
           </FlagGroup>
           <section id="admin-page">
               <header>
                   <h1 className="poppins-font font-bold text-2xl mb-3">Espace administrateur</h1>
                   <p>Cet page vous permet d’ajouter des utilisateurs, d’en</p>
                   <p>supprimer, ou de modifier les informations de leur profil.</p>
               </header>
               <div className="mt-10 flex flex-row justify-between">
                   <Link to="users/create" className="bg-yellow-500 py-2 px-4 rounded font-bold h-full">
                       <FontAwesomeIcon icon={faPlus} />
                       <span className="pl-2">Ajouter un nouvel utilisateur</span>
                   </Link>
                   <div id="filter-user-input" className="ml-2 relative flex items-center w-32 basis-1/3">
                       <input
                           type="text"
                           placeholder="Filtrer par prénom, nom ou email"
                           onChange={(e) => {
                               const queryText = e.target.value;
                               if(queryText?.trim().length > 2){
                                   setQuery(e.target.value)
                               }else{
                                   setQuery('')
                               }
                           }}
                           className="w-full h-full pl-4 pr-9 border rounded shadow focus:outline-none"/>
                       <div className="absolute right-4">
                           <FontAwesomeIcon icon={faSearch} className="text-gray-500 hover:text-gray-700 hover:cursor-pointer"/>
                       </div>
                    </div>
                   <div className="ml-2 flex items-center">
                       <FontAwesomeIcon
                           icon={faTrash}
                           className={ Array.isArray(selectedUsers) && selectedUsers?.length > 0 ? `text-black hover:cursor-pointer` : `text-gray-400`  }
                            onClick={ () => {
                                if(selectedUsers?.length > 0){
                                    setShowModal(true)
                                }
                            }}
                       />
                   </div>
               </div>

               { loading ? (
                   <div className="my-10">
                       <SoanLoader show={true} size="extra-small"/>
                   </div>
                ) : (
                    <Fragment>
                        { showAlert && state?.alert && (
                            <Alert
                                onClose={() => {
                                    setShowAlert(false);
                                }}
                                severity={state.alert.type}
                                className="mt-5">
                                {state.alert.message}
                            </Alert>
                        )}
                        <UsersList
                            users={users}
                            selectedUsers={selectedUsers}
                            toggleSelectUser={ (userId) => {
                                if(Array.isArray(selectedUsers) && selectedUsers.includes(userId)){
                                    setSelectedUsers(selectedUsers.filter(id => id !== userId));
                                }else{
                                    setSelectedUsers([...selectedUsers, userId]);
                                }
                            }}
                            selectAll={ () => {
                                if(Array.isArray(selectedUsers) && selectedUsers.length > 0){
                                    setSelectedUsers([]);
                                }else {
                                    setSelectedUsers(users.map(user => user.id))
                                }
                            } } />

                        <div className="flex mt-5 justify-center">
                            <Pagination
                                count={totalPages}
                                shape="rounded"
                                variant="outlined"
                                onChange={(e, page) => setPage(page)}/>
                        </div>
                    </Fragment>
               )}
           </section>
       </div>
    )
}

export default AdminPage;