import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

const ReactHookInputField = ({name, inputValue, label, placeholder, type, register, error, customRightIcon}) => {
    return (
        <div className="input-field flex flex-col mb-6">
            <label htmlFor={name} className="poppins-font">{label}</label>
            <div className="relative w-full mt-2 flex items-center">
                <input
                    id={name}
                    placeholder={placeholder}
                    type={type}
                    {...register(name, { value : inputValue ?? '' })}
                    className={`w-full py-2 px-4 outline-none rounded border ${ error?.message ? 'border-red-600' : 'focus:border-gray-400'}`}/>
                { error?.message  && customRightIcon }
            </div>
            <div>
                { error && <p className="text-red-600 text-sm mt-2">{error.message}</p> }
            </div>
        </div>
    )
}

ReactHookInputField.defaultProps = {
    type: "text",
    error: {},
    value: "",
    customRightIcon: (
        <FontAwesomeIcon
            icon={faExclamationCircle}
            className="absolute text-red-600 right-4" />
    ),

}

export default ReactHookInputField;