import registeredRoutes from "./routes";
import './App.css';

const App = () => (
    <div className="App">
        { registeredRoutes }
    </div>
);


export default App;
