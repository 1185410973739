import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import APIService from "../../utils/APIService";
import { useParams } from "react-router-dom";
import {
  faPen,
  faPlus,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import InputField from "../../components/form/InputField";
import MultipleTagInput from "../../components/form/MultipleTagInput";
import ConditionsDialog from "../../components/ConditionsDialog.jsx";
import SoanLoader from "../../components/SoanLoader";
import { AuthContext } from "../../contexts/AuthContext";

const MailsEditPage = () => {
  const { user } = useContext(AuthContext);
  const { mailId } = useParams();
  const [mail, setMail] = useState({
    id: null,
    name: "",
    conditions: [],
    event: null,
    content: "",
    expeditorMail: "",
    expeditorName: "",
    recipients: [],
    ccRecipients: [],
    cciRecipients: [],
    replyTo: "",
    subject: "",
    headerTitle: "",
    headerSubtitle: "",
    ctaText: "",
    ctaLink: "",
    status: "DRAFT",
  });

  const [conditions, setConditions] = useState(null);
  const [conditionData, setConditionData] = useState(null);
  const [isConditionDialogOpen, setIsConditionDialogOpen] = useState(false);
  const [blocs, setBlocs] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const editorRef = useRef(null);
  const conditionInputRef = useRef(null);
  const [alert, setAlert] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewContent, setPreviewContent] = useState(null);
  const [sendingTestMail, setSendingTestMail] = useState(false);

  const api = new APIService();

  useEffect(() => {
    api
      .get("/blocks?pagination=false")
      .then((res) => res.json())
      .then((data) => {
        const hydraMember = data["hydra:member"] ?? [];
        setBlocs(hydraMember);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    api
      .get("/events?pagination=false")
      .then((res) => res.json())
      .then((data) => {
        const hydraMember = data["hydra:member"] ?? [];
        setEvents(hydraMember);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (mailId) {
      api
        .get(`/mails/${mailId}`)
        .then((res) => res.json())
        .then((data) => {
          setMail({
            name: data.name,
            content: data.content,
            description: data.description,
            expeditorMail: data.expeditorMail,
            expeditorName: data.expeditorName,
            recipients: data.recipients,
            ccRecipients: data.ccRecipients,
            cciRecipients: data.cciRecipients,
            subject: data.subject,
            headerTitle: data.headerTitle,
            headerSubtitle: data.headerSubtitle,
            ctaText: data.ctaText ?? "",
            ctaLink: data.ctaLink ?? "",
            event: data.event,
            conditions: data.conditions,
            replyTo: data?.replyTo ?? "",
            status: data?.status ?? "DRAFT",
          });
          setConditions(data.conditions);
          setSelectedEvent(data.event);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    if (isPreviewOpen) {
      api
        .post("/preview", {
          content: editorRef.current.getData(),
          variables: {
            header_subtitle: mail?.headerSubtitle ?? "Sous-titre",
            header_title: mail?.headerTitle ?? "Titre",
            ctaText: mail?.ctaText ?? null,
            ctaLink: mail?.ctaLink ?? null,
          },
        })
        .then((res) => res.json())
        .then((data) => {
          setPreviewContent(data.html);
        })
        .catch((err) => console.log(err));
    }
  }, [isPreviewOpen]);

  const updateMail = (event) => {
    setMail({
      ...mail,
      [event.target.name]: event.target.value,
    });
  };

  const sendTestMail = (event) => {
    setSendingTestMail(true);

    const params = {
      recipient: user.email,
      subject: mail?.subject ?? "Soan test mail",
      content: editorRef.current.getData(),
      variables: {
        header_subtitle: mail?.headerSubtitle ?? "Sous-titre",
        header_title: mail?.headerTitle ?? "Titre",
      },
    };

    if (mail?.ctaText && mail?.ctaLink) {
      params.variables["ctaText"] = mail?.ctaText;
      params.variables["ctaLink"] = mail?.ctaLink;
    }

    api
      .post("/send-test-mail", params)
      .then((res) => {
        setSendingTestMail(false);
      })
      .catch((err) => {
        setSendingTestMail(false);
        console.log(err);
      });
  };

  const submitEmail = (event) => {
    const emailData = {
      ...mail,
      event: selectedEvent ? selectedEvent["@id"] : events[0]["@id"],
      conditions: conditions ?? [],
      content: editorRef?.current?.getData() ?? "",
    };

    if (mailId) {
      api
        .patch(`/mails/${mailId}`, emailData)
        .then((res) => {
          if (res.status === 200) {
            setAlert({
              type: "success",
              message: "Mail mis à jour avec succès",
            });
          } else {
            setAlert({
              type: "error",
              message: "Une erreur est survenue lors de la mise à jour du mail",
            });
          }
        })
        .catch((err) =>
          setAlert({
            type: "error",
            message: "Une erreur est survenue lors de la mise à jour du mail",
          })
        );
    } else {
      api
        .post("/mails", emailData)
        .then((res) => {
          if (res.status === 201) {
            setAlert({
              type: "success",
              message: "Mail créé avec succès",
            });
          } else {
            setAlert({
              type: "error",
              message: "Une erreur est survenue lors de la création du mail",
            });
          }
        })
        .catch((err) => {
          setAlert({
            type: "error",
            message: "Une erreur est survenue lors de la création du mail",
          });
        });
    }
  };

  const displayConditionValue = (val) => {
    if (typeof val === "boolean") {
      return val.toString();
    }
    return val;
  };

  return (
    <div id="mails-edit-page">
      {alert?.type && alert?.message ? (
        <Alert
          variant="filled"
          severity={alert?.type}
          className="mb-5"
          onClose={() => setAlert(null)}
        >
          {alert?.message}
        </Alert>
      ) : null}
      <Dialog
        open={isPreviewOpen}
        fullWidth={true}
        maxWidth="md"
        onClose={() => setIsPreviewOpen(false)}
      >
        <DialogTitle className="poppins-font">
          <div className="flex justify-between items-center">
            <span className="font-bold">
              Prévisualisation du modèle de mail
            </span>
            <div>
              <button
                onClick={() => {
                  setIsPreviewOpen(false);
                  setPreviewContent(null);
                  setSendingTestMail(true);
                  sendTestMail(null);
                }}
                disabled={sendingTestMail}
                className="text-sm text-white bg-gray-700 hover:bg-black py-3 px-4 rounded mr-10"
              >
                Envoie-moi un mail de test
              </button>
              <button
                className="p-2 text-gray-400 hover:text-gray-800"
                onClick={() => setIsPreviewOpen(false)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {previewContent ? (
            <div className="h-[80vh]">
              <iframe
                title="HTML Content"
                className="w-full h-full"
                srcDoc={previewContent ?? ""}
              />
            </div>
          ) : (
            <SoanLoader show={isPreviewOpen} size="extra-small" />
          )}
        </DialogContent>
      </Dialog>
      <div className="flex">
        <div className="w-1/3">
          <Accordion>
            <AccordionSummary
              style={{
                borderBottom: "1px solid #e0e0e0",
              }}
              expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
            >
              <h3 className="poppins-font">Informations du mail</h3>
            </AccordionSummary>
            <AccordionDetails className="h-[50vh] overflow-auto">
              <InputField
                label="Nom du mail"
                size="small"
                value={mail.name}
                name="name"
                placeholder="Nom du mail"
                onChange={updateMail}
              />
              <InputField
                label="Nom de l'expéditeur"
                name="expeditorName"
                placeholder="Adresse email de l'expéditeur"
                value={mail.expeditorName}
                onChange={updateMail}
              />
              <InputField
                name="expeditorMail"
                placeholder="Adresse email de l'expéditeur"
                label="Adresse email de l'expéditeur"
                value={mail.expeditorMail}
                onChange={updateMail}
              />
              <MultipleTagInput
                label="Adresses emails des destinataires"
                name="recipients"
                values={mail.recipients}
                onChange={(values) => {
                  setMail({
                    ...mail,
                    recipients: values,
                  });
                }}
              />
              <InputField
                name="headerTitle"
                placeholder="Titre de l'en-tête"
                label="Titre de l'en-tête (Header)"
                value={mail.headerTitle}
                onChange={updateMail}
              />
              <InputField
                name="headerSubtitle"
                placeholder="Sous-titre de l'en-tête"
                label="Sous-titre de l'en-tête (Header)"
                value={mail.headerSubtitle}
                onChange={updateMail}
              />
              <InputField
                name="ctaText"
                placeholder="Texte CTA"
                label="Texte CTA"
                value={mail.ctaText}
                onChange={updateMail}
              />
              <InputField
                name="ctaLink"
                placeholder="Lien CTA"
                label="Lien CTA"
                value={mail.ctaLink}
                onChange={updateMail}
              />
              <InputField
                name="replyTo"
                placeholder="Adresse email de réponse"
                label="Adresse email de réponse (reply-to)"
                value={mail.replyTo}
                onChange={updateMail}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              style={{
                borderBottom: "1px solid #e0e0e0",
              }}
              expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
            >
              <h3 className="poppins-font">Evénement & Variables</h3>
            </AccordionSummary>
            <AccordionDetails>
              <select
                className="bg-white border border-gray-300 rounded p-2 max-w-full"
                onChange={(e) => {
                  console.log(
                    events.find(
                      (eventData) => eventData.name === e.target.value
                    )
                  );
                  setSelectedEvent(
                    events.find(
                      (eventData) => eventData.name === e.target.value
                    )
                  );
                }}
              >
                {events.map((event, index) => {
                  return (
                    <option
                      key={index}
                      selected={event.name === selectedEvent?.name}
                      value={event.name}
                    >
                      {event.name}
                    </option>
                  );
                })}
              </select>

              <div className="h-full my-5">
                <p className="text-sm poppins-font border-b-2 mb-2">
                  Variables de l'événement:
                </p>
                {selectedEvent?.datas?.length > 0 ? (
                  selectedEvent?.datas?.map((variable, index) => {
                    return (
                      <div
                        key={index}
                        draggable={true}
                        onDragStart={(ev) => {
                          ev.dataTransfer.clearData();
                          ev.dataTransfer.setData(
                            "text/plain",
                            `{{ ${variable} }}`
                          );
                        }}
                        className="flex mb-3 justify-between border bg-gray-100 hover:bg-gray-300 hover:cursor-pointer rounded py-1 px-2"
                      >
                        <p className="mr-2 text-md">{variable}</p>
                      </div>
                    );
                  })
                ) : (
                  <Alert severity="warning">
                    Aucune variable disponible pour cet événement
                  </Alert>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              style={{
                borderBottom: "1px solid #e0e0e0",
              }}
              expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
            >
              <h3 className="poppins-font">Blocs</h3>
            </AccordionSummary>
            <AccordionDetails>
              <div className="h-[40vh] px-2 overflow-y-scroll">
                {blocs.map((bloc, index) => (
                  <div
                    key={index}
                    onDragStart={(ev) => {
                      ev.dataTransfer.clearData();
                      ev.dataTransfer.setData(
                        "text/plain",
                        `{{ #${bloc.name} }}`
                      );
                    }}
                    draggable={true}
                    className="flex mb-3 justify-between border bg-gray-100 hover:bg-gray-300 hover:cursor-pointer rounded py-1 px-2"
                  >
                    <Tooltip
                      title={bloc.description || "Description not available"}
                      arrow
                    >
                      <p className="mr-2 text-md w-full text-center">
                        {bloc.name}
                      </p>
                    </Tooltip>
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              style={{
                borderBottom: "1px solid #e0e0e0",
              }}
              expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
            >
              <h3 className="poppins-font">Conditions</h3>
            </AccordionSummary>
            <AccordionDetails>
              <ConditionsDialog
                isOpen={isConditionDialogOpen}
                onClose={() => setIsConditionDialogOpen(false)}
                onAdd={(condition) => {
                  let conditionValue = condition.value.trim().toLowerCase();
                  setConditions({
                    ...conditions,
                    [condition.key]: ["true", "false", "vrai", "faux"].includes(
                      conditionValue
                    )
                      ? condition.value === "true" || condition.value === "vrai"
                      : condition.value,
                  });
                }}
                data={conditionData}
              />
              {selectedEvent?.name ? (
                <div>
                  {selectedEvent?.datas?.length > 0 ? (
                    <div className="flex">
                      <select
                        ref={conditionInputRef}
                        className="border rounded bg-white w-full py-2 px-3 mr-2"
                      >
                        {selectedEvent.datas.map((variable, index) => (
                          <option key={index} value={variable}>
                            {variable}
                          </option>
                        ))}
                      </select>
                      <button
                        onClick={() => {
                          setIsConditionDialogOpen(true);
                          setConditionData({
                            key: conditionInputRef.current.value,
                          });
                        }}
                        className="bg-[#00E5DA] px-4 rounded"
                      >
                        Ajouter
                      </button>
                    </div>
                  ) : (
                    <Alert severity="warning">
                      Aucune variable n'est disponible pour cet événement
                    </Alert>
                  )}
                </div>
              ) : (
                <Alert severity="warning">Aucun événement sélectionné</Alert>
              )}
              <div className="mt-5">
                {conditions && Object.entries(conditions).length > 0 && (
                  <table className="w-full text-center">
                    <thead>
                      <tr className="bg-gray-100 border-b border-gray-200">
                        <th className="p-2">Variable</th>
                        <th className="p-2">Valeur</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {conditions &&
                        Object.entries(conditions).map(
                          ([key, value], index) => {
                            return (
                              <tr key={index}>
                                <td className="p-2">{key}</td>
                                <td className="p-2">
                                  {displayConditionValue(value)}
                                </td>
                                <td className="p-2">
                                  <FontAwesomeIcon
                                    icon={faPen}
                                    onClick={() => {
                                      // edit condition
                                      setIsConditionDialogOpen(true);
                                      setConditionData({
                                        key,
                                        value,
                                      });
                                    }}
                                    className="ml-3 hover:cursor-pointer text-green-700"
                                  />
                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    onClick={() => {
                                      const newConditions = { ...conditions };
                                      delete newConditions[key];
                                      setConditions(newConditions);
                                    }}
                                    className="ml-3 hover:cursor-pointer text-red-500"
                                  />
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              style={{
                borderBottom: "1px solid #e0e0e0",
              }}
              expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
            >
              <h3 className="poppins-font">Description</h3>
            </AccordionSummary>
            <AccordionDetails>
              <textarea
                name="description"
                onChange={updateMail}
                defaultValue={mail?.description}
                className="w-full h-40 mt-4 border-2 border-gray-600 p-4"
                placeholder="description du mail"
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              style={{
                borderBottom: "1px solid #e0e0e0",
              }}
              expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
            >
              <h3 className="poppins-font">Status</h3>
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex items-center">
                <select
                  name="status"
                  onChange={updateMail}
                  className="w-full px-4 py-2 bg-white rounded border border-gray-300 focus:border-gray-600"
                >
                  <option selected={mail?.status === "DRAFT"} value="DRAFT">
                    BROUILLON
                  </option>
                  <option
                    selected={mail?.status === "PUBLISHED"}
                    value="PUBLISHED"
                  >
                    PUBLIÉ
                  </option>
                  <option
                    selected={mail?.status === "ARCHIVED"}
                    value="ARCHIVED"
                  >
                    ARCHIVÉ
                  </option>
                </select>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="w-2/3 ml-5">
          <div className="mb-5">
            <h3 className="text-lg font-bold mb-2">Objet du mail</h3>
            <TextField
              placeholder={"Objet du mail"}
              size={"small"}
              name="subject"
              className="w-full"
              value={mail?.subject ?? ""}
              onChange={updateMail}
            />
          </div>
          <div className="my-3">
            <h3 className="text-lg font-bold mb-2">Contenu du mail</h3>
            <CKEditor
              editor={ClassicEditor}
              ref={editorRef}
              config={{
                toolbar: {
                  items: [
                    "paragraph",
                    "bold",
                    "italic",
                    "link",
                    "undo",
                    "redo",
                  ],
                },
              }}
              className="h-[80vh]"
              data={mail?.content ?? ""}
              onReady={(editor) => {
                // setEditor(editor)
                editorRef.current = editor;
              }}
            />
          </div>
          <div className="mt-5">
            {(user?.roles?.includes("ROLE_ADMIN") ||
              user?.roles?.includes("ROLE_EDITOR")) && (
              <button
                onClick={submitEmail}
                className="bg-[#00E5DA] font-bold mr-2 py-2 px-8 rounded"
              >
                Enregistrer ce modèle de mail
              </button>
            )}
            <button
              onClick={() => setIsPreviewOpen(true)}
              className="bg-gray-300 font-bold py-2 px-8 rounded hover:bg-gray-400"
            >
              Prévisualiser
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailsEditPage;
