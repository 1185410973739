import React, {Fragment, useContext, useEffect, useState} from 'react';
import APIService from "../../utils/APIService";
import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPen,
    faPlus,
    faSearch,
    faTrash,
    faFilter,
    faAngleUp,
    faAngleDown, faEye, faEdit, faSort, faArrowDownZA, faArrowDownAZ, faFileExport
} from "@fortawesome/free-solid-svg-icons";

import SoanLoader from "../../components/SoanLoader";
import CustomModalDialog from "../../components/Modal";
import Alert from "../../components/Alert";
import {Badge, ClickAwayListener, Pagination, Popover} from "@mui/material";
import {parseDate} from "../../utils/date";
import Clamp from "react-multiline-clamp";
import {AuthContext} from "../../contexts/AuthContext";

const MailsPage = (props) => {
    const {user} = useContext(AuthContext);
    const [orderBy, setOrderBy] = useState('modifiedAt');
    const [order, setOrder] = useState('DESC');
    const [loading, setLoading] = useState(true);
    const [showFilter, setShowFilter] = useState(false);
    const [eventFilterQuery, setEventFilterQuery] = useState('');
    const [mails, setMails] = useState([]);
    const [events, setEvents] = useState([]);
    const [selectedMails, setSelectedMails] = useState([]);
    const [selectedEvents, setSelectedEvents] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showExportModal, setShowExportModal] = useState(false);
    const [alert, setAlert] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [query, setQuery] = useState('');

    const [filtersUrl, setFiltersUrl] = useState('');

    const api = new APIService();

    useEffect(() => {
        api.get(`/mails?page=${page}&orSearch[name]=${query}&orSearch[description]=${query}&${filtersUrl}&order[${orderBy}]=${order}`)
            .then(res => res.json())
            .then(data => {
                let hydraMembers = data['hydra:member'] || [];
                setMails(hydraMembers);
                setTotalPages(Math.ceil((data['hydra:totalItems'] || 0) / 10));
                setLoading(false);
            })
            .catch(err => console.log(err))
    }, [page, query, filtersUrl, order, orderBy])

    useEffect(() => {
        api.get('/events?pagination=false')
            .then(res => res.json())
            .then(data => {
                setEvents(data['hydra:member'])
            })
            .catch(err => console.log(err))
    }, [])


    const deleteMails = () => {
        for(const mailId of selectedMails){
            api.delete(`/mails/${mailId}`)
                .then(response => {
                    if(response.status === 204){
                        setAlert({
                            type: 'success',
                            message: "Les modèles de mails sélectionnés ont été supprimés avec succès."
                        });
                        setShowDeleteModal(false);
                        setSelectedMails([]);
                        setMails(mails.filter(mail => mail.id !== mailId));
                    }else{
                        setAlert({
                            type: 'error',
                            message: "Une erreur est survenue lors de la suppression des modèles de mails sélectionnés."
                        });
                    }
                })
                .catch(err => {
                    console.log(err)
                    setAlert({
                        type: 'error',
                        message: "Une erreur est survenue lors de la suppression des modèles de mails."
                    })
                })
        }
    }

    const getOrderIcon = fieldName => {
        if(fieldName !== orderBy){
            return faSort;
        }
        if(fieldName === orderBy && 'DESC' === order){
            return faArrowDownZA;
        }
        return faArrowDownAZ;
    }

    return (
        <div id="mails" className="mb-10">
            <CustomModalDialog
                isOpen={showDeleteModal}
                onCancel={() => setShowDeleteModal(false) }
                onConfirm={() => {
                    deleteMails()
                }}
                headerTitle="Confirmation de la suppression"
                bodyContent="Êtes-vous sûr de vouloir supprimer les modèles de mails sélectionnés ?"
            />
            <section>
                <header>
                    <h1 className="poppins-font mb-4 text-2xl">Modèles de mail</h1>
                    <p>Cet page vous permet de voir, créer, modifier ou supprimer <br/> des modèles de mail.</p>
                </header>
                <div className="mt-10 flex justify-between">
                    <div className="flex">
                        {
                            user?.roles.includes('ROLE_ADMIN') ? (
                                <Fragment>
                                    <Link to="/mails/create" className="bg-[#00E5DA] font-bold py-2 px-8 rounded">
                                        <FontAwesomeIcon icon={faPlus} className="mr-4" />
                                        <span>Ajouter</span>
                                    </Link>
                                    <Link
                                        to={selectedMails.length > 0 ? "/mails/exports" : null}
                                        state={{ selectedMails: mails.filter(mail => selectedMails.includes(mail.id))}}
                                        className={
                                        `bg-neutral-700 text-white `+
                                        `font-bold py-2 px-8 rounded ml-3 `+
                                        `${selectedMails.length > 0 ? 'hover:bg-neutral-900' : 'opacity-50 cursor-not-allowed'}`
                                    }>
                                        <FontAwesomeIcon icon={faFileExport} className="mr-4" />
                                        <span>Exporter</span>
                                    </Link>
                                </Fragment>
                            ): <div className="w-72"/>
                        }
                    </div>
                    <div className="ml-2 relative flex items-center w-32 basis-1/3">
                        <input
                            type="text"
                            placeholder="Filtrer par nom"
                            onChange={(e) => {
                                const queryText = e.target.value;
                                if(queryText?.trim().length > 2){
                                    setQuery(e.target.value)
                                }else{
                                    setQuery('')
                                }
                            }}
                            className="w-full h-full rounded shadow border pl-4 pr-9 focus:border-gray-300 focus:outline-none"/>
                        <FontAwesomeIcon
                            icon={faSearch}
                            className="absolute text-gray-500 hover:text-gray-700 hover:cursor-pointer right-4"/>
                    </div>
                    <div className="relative" id="filter">
                        <button
                            onClick={() => setShowFilter(!showFilter)}
                            className="bg-white shadow py-2 px-5 rounded mr-20">
                            <FontAwesomeIcon
                                className="mr-4"
                                icon={faFilter} />
                            <span className="mr-4">Filtrer</span>
                            {
                                filtersUrl !== "" && <Badge badgeContent={selectedEvents.length} color="primary" />
                            }
                        </button>
                        <Popover
                            open={showFilter}
                            anchorEl={document.getElementById('filter')}
                            elevation={1}
                            style={{
                                marginTop: '0.2rem'
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                        >
                            <ClickAwayListener onClickAway={() => setShowFilter(false)}>
                                <div className="px-4 py-5 w-30" id="poppin-filter-by-events">
                                    <p className="poppins-font text-md mb-5">Filtrer les mails par déclencheur</p>
                                    <ul className="my-3 max-h-80 overflow-auto pr-2 h-50">
                                        {
                                            events && events.map((event, index) => (
                                                <li
                                                    key={index}
                                                    className="py-1"
                                                >
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            value={event.id}
                                                            className="mr-2"
                                                            checked={selectedEvents.includes(event.id)}
                                                            onChange={(e) => {
                                                                if(selectedEvents.includes(event.id)){
                                                                    setSelectedEvents(selectedEvents.filter(id => id !== event.id))
                                                                }else{
                                                                    setSelectedEvents([...selectedEvents, event.id])
                                                                }
                                                            }}
                                                        />
                                                        <span>{event.name}</span>
                                                    </label>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    <button
                                        className="bg-gray-900 text-white px-4 py-2 rounded mt-4 mr-4"
                                        onClick={() => {
                                            setFiltersUrl(selectedEvents.map(id => `event.id[]=${id}`).join('&'));
                                            setShowFilter(false)
                                        }}
                                    >Appliquer</button>
                                    <button
                                        onClick={() => {
                                            setSelectedEvents([]);
                                            setFiltersUrl('');
                                            setShowFilter(false);
                                        }}
                                        className="border px-4 py-2 rounded">Réinitialiser</button>
                                </div>
                            </ClickAwayListener>
                        </Popover>
                    </div>
                    <div className="flex items-center justify-between">
                        {
                            user?.roles.includes('ROLE_ADMIN') && (
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    className={
                                        selectedMails.length > 0 ? 'text-black hover:cursor-pointer' : 'text-gray-400'
                                    }
                                    onClick={ () => {
                                        if(selectedMails?.length > 0) {
                                            setShowDeleteModal(true)
                                        }
                                    }}/>
                            )
                        }
                    </div>
                </div>
            </section>
            <section>
                <Alert
                    type={alert?.type}
                    message={alert?.message}
                    show={alert?.type !== undefined} />
                { loading ?
                    <SoanLoader show={loading} /> : (
                        <table className="w-full mt-10 border-separate border-spacing-y-2 table-fixed">
                            <thead>
                            <tr className="poppins-font">
                                <th className="w-12 text-left"></th>
                                <th className="w-72 text-left">
                                    <span>Nom du mail</span>
                                </th>
                                <th className="w-96 text-left">Description</th>
                                <th className="w-60 text-left">
                                    <span>Date de création</span>
                                    <FontAwesomeIcon
                                        icon={getOrderIcon('createdAt')}
                                        className="ml-2 hover:cursor-pointer"
                                        onClick={() => {
                                            setOrderBy('createdAt')
                                            setOrder(order === 'DESC' ? 'ASC' : 'DESC')
                                        }}
                                    />
                                </th>
                                <th className="w-60 text-left">
                                    <span>Dernière modification</span>
                                    <FontAwesomeIcon
                                        icon={getOrderIcon('modifiedAt')}
                                        className="ml-2 hover:cursor-pointer"
                                        onClick={() => {
                                            setOrderBy('modifiedAt')
                                            setOrder(order === 'DESC' ? 'ASC' : 'DESC')
                                        }}
                                    />
                                </th>
                                <th className="w-20 text-left"></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                mails && mails.map((mail, index) => (
                                    <tr
                                        key={index}
                                        className="bg-white shadow">
                                        <td className="p-4 text-left">
                                            <input
                                                type="checkbox"
                                                className="form-checkbox"
                                                onChange={ (e) => {
                                                    if(e.target.checked) {
                                                        setSelectedMails([...selectedMails, mail.id])
                                                    } else {
                                                        setSelectedMails(selectedMails.filter(id => id !== mail.id))
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td className="py-4 text-left">
                                            <Link
                                                to={{
                                                    pathname: `/mails/${mail.id}/edit`,
                                                    state: {
                                                        emailData: mail
                                                    }
                                                }}
                                                className="poppins-font">

                                                {mail.name}
                                            </Link>
                                            <p className="text-xs text-gray-600">
                                                Crée par <span className="font-bold">{ mail?.createdBy?.firstName || "Ce compte n'existe plus"}</span>
                                            </p>
                                        </td>
                                        <td className="py-4 text-left">
                                            <div className="flex items-center pr-4">
                                                <Clamp
                                                    showLines={2}
                                                    withToggle={true}
                                                    showMoreElement={({toggle}) => (
                                                        <FontAwesomeIcon
                                                            icon={faAngleDown}
                                                            onClick={toggle}
                                                            className="text-gray-400 hover:text-black hover:cursor-pointer" />
                                                    )}
                                                    showLessElement={({toggle}) => (
                                                        <FontAwesomeIcon
                                                            icon={faAngleUp}
                                                            onClick={toggle}
                                                            className="text-gray-400 hover:text-black hover:cursor-pointer" />
                                                    )}
                                                >
                                                    <span>{mail?.description ?? ''}</span>
                                                </Clamp>
                                            </div>
                                        </td>
                                        <td className="py-4 text-left">{ parseDate(mail.createdAt) || "-"}</td>
                                        <td className="py-4 text-left">{ parseDate(mail.modifiedAt) || "-"}</td>
                                        <td className="py-4 text-left">
                                            <Link to={`/mails/${mail.id}/edit`}>
                                                <FontAwesomeIcon icon={
                                                    (user?.roles.includes('ROLE_ADMIN') || user?.roles.includes('ROLE_EDITOR')) ? faPen : faEye
                                                } className="mr-4 text-gray-500 hover:text-gray-700 hover:cursor-pointer" />
                                            </Link>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    )
                }
            </section>
            <section className="flex mt-5 justify-center">
                <Pagination
                    count={totalPages}
                    shape="rounded"
                    variant="outlined"
                    onChange={(e, page) => setPage(page)}/>
            </section>
        </div>
    )
}

export default MailsPage;