import React, {useEffect, useRef, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import {Button} from "@mui/material";
const ConditionsDialog = ({isOpen, onClose, data, onAdd}) => {
    const [condition, setCondition] = useState({
        key: data?.key ?? '',
        value: data?.value ?? ''
    });

    useEffect(() => {
        if(data !== null) {
            setCondition({
                key: data.key,
                value: data.value ?? ''
            });
        }
    }, [data]);


    return (
        <Dialog
            open={isOpen}
            fullWidth={true}
            maxWidth="xs">
            <DialogTitle>
                { (data?.key && data?.value) ? 'Modifier la condition' : 'Ajouter une condition' }
            </DialogTitle>
            <Divider/>
            <DialogContent>
                <div
                    className="flex flex-col">
                    <div className="mb-5">
                        <label className="text-bold">Nom de la variable</label>
                        <input
                            name="key"
                            type="text"
                            disabled
                            className="focus:outline-none focus:border-gray-600 bg-gray-200 border-solid border-2 border-gray-300 rounded px-2 py-1 w-full"
                            required
                            value={condition.key}
                            placeholder="Variable"/>
                    </div>
                    <div className="mb-5">
                        <label className="block">Valeur</label>
                        <input
                            type="text"
                            required
                            name="value"
                            value={condition.value}
                            onChange={(e) => setCondition({...condition, value: e.target.value})}
                            className={`h-11  border-solid border-2 focus:outline-none rounded p-2 w-full ${condition.value.length === 0 ? 'focus:border-red-600 border-red-500' : 'focus:border-gray-600 border-gray-300'}`}
                            placeholder="Valeur"/>
                        { condition.value.length === 0 && <p className="text-red-600 py-2">La valeur ne peut pas être vide</p> }
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onClose();
                        setCondition({
                            key: '',
                            value: ''
                        });
                    }}
                    >Annuler</Button>
                <Button
                    disabled={condition.value.length === 0}
                    onClick={() => {
                        if(typeof condition.value && condition.value.length > 0){
                            onClose();
                            onAdd(condition);
                            setCondition({
                                key: '',
                                value: ''
                            });
                        }else{
                            
                        }

                    }}
                    className="bg-red-200">Sauvegarder</Button>
            </DialogActions>
        </Dialog>
    )
}

ConditionsDialog.defaultProps = {
    open: false,
    data: {
        key: '',
        value: 'true'
    },
}

export default ConditionsDialog;