import {useEffect, useState} from "react";
import {Chip, TextField} from "@mui/material";

const MultipleTagInput = ({values, label, placeholder, onChange}) => {
    const [tagsInput, setTagsInput] = useState(values);

    const onDropHandler = (event) => {
        let droppedValue = event.dataTransfer.getData("text")
        // remove brackets if present {{ }}
        if(droppedValue.length > 0){
            droppedValue = droppedValue.replace("{{", "").replace("}}", "")
        }
        setTagsInput([...tagsInput, droppedValue]);
        onChange([...tagsInput, droppedValue]);
    }

    useEffect(() => {
        setTagsInput(values)
    }, [values]);

    const tagsList = tagsInput.map((tag, index) => (
            <div
                key={index}
                className="mb-2 mx-2">
                <Chip
                    label={tag}
                    onDelete={() => {
                        setTagsInput(tagsInput.filter((_, i) => i !== index))
                        onChange(tagsInput.filter((_, i) => i !== index))
                    }}
                />
            </div>
        ))
    return (
        <div className="mb-5">
            <label className="block poppins-font mb-2" htmlFor="tags">{label}</label>
            <div className="flex flex-wrap my-2 py-2.5 border rounded border-gray-300 hover:border-gray-900">
                <div className="flex flex-wrap"
                     onDragOver={e => {
                        e.stopPropagation();
                        e.preventDefault();
                     }}
                     onDrop={onDropHandler}>
                    {tagsList}
                </div>
                <input
                    type="text"
                    placeholder={placeholder}
                    className="w-full outline-none bg-transparent ml-3 flex-glow"
                    onKeyUp={event => {
                        if(event.key === "Enter" && event.target.value !== ""){
                            setTagsInput([...tagsInput, event.target.value]);
                            onChange([...tagsInput, event.target.value]);
                            event.target.value = ""
                        }
                    }}
                    onDrop={event => {
                        event.stopPropagation();
                        event.preventDefault();
                        onDropHandler(event)
                        event.target.value = "";
                    }}
                />
            </div>
        </div>
    )
}

MultipleTagInput.defaultProps = {
    defaultTags: [],
    label: "",
    placeholder: "Ajouter un adresse mail",
    onChange: (tags) => {}
}
export default MultipleTagInput;