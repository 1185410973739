import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash, faTimes} from "@fortawesome/free-solid-svg-icons";
import Error from "../Error";
import {Alert, AlertTitle} from "@mui/material";
import SoanLoader from "../SoanLoader";

const LoginForm = ({login, error, cancelError}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);


    useEffect(() => {
        if (error) {
            setIsLoggingIn(false)
        }
    }, [error])

    return (
        <div id="form" className="mt-5">
            {error && (
                <Alert severity="error" action={
                    <button>
                        <FontAwesomeIcon icon={faTimes} onClick={() => cancelError()}/>
                    </button>
                }
                       className="my-6"
                >
                    <AlertTitle>
                        <b>Identifiants invalides</b>
                    </AlertTitle>
                    Veuillez vérifier les informations renseignées.
                </Alert>
            )}

            <div className="inputField">
                <label htmlFor="emailInput" className="block">Email</label>
                <input
                    id="emailInput"
                    type="email"
                    required
                    className="block w-full rounded border-gray-400 border p-2 mt-2 mb-5"
                    placeholder="Adresse e-mail"
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}/>
            </div>
            <div className="inputField">
                <label htmlFor="passwordInput">Mot de passe</label>
                <div className="relative flex  mt-2 mb-5 items-center">
                    <input
                        id="passwordInput"
                        type={showPassword ? "text" : "password"}
                        required
                        className="block w-full rounded border-gray-400 border py-2 pl-2 pr-8"
                        placeholder="Mot de passe"
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}/>
                    <FontAwesomeIcon
                        icon={!showPassword ? faEye : faEyeSlash}
                        className="absolute right-2 hover:cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}/>
                </div>
            </div>
            <button
                type="submit"
                id="loginBtn"
                disabled={isLoggingIn}
                className={`block text-white rounded w-full p-2 mt-10 h-124 ${isLoggingIn ? 'bg-gray-100 border-b-2 border border-gray-200' : 'bg-purple hover:bg-purple-light'}`}
                onClick={() => {
                    login(email, password)
                    setIsLoggingIn(true)
                    cancelError()
                }}
            >
                {isLoggingIn ? (
                    <SoanLoader show={true} size="loader"/>
                ) : (
                    <span>Se connecter</span>
                )}
            </button>
        </div>
    );
}

LoginForm.defaultProps = {
    error: false
}
export default LoginForm;