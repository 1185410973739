import {
    Route,
    Routes,
    Navigate
} from "react-router-dom";

import Login from "./pages/auth/Login";
import DashboardLayout from "./pages/DashboardLayout";
import DashboardPage from "./pages/DashboardPage";
import AdminPage from "./pages/AdminPage";
import MailsPage from "./pages/mails/MailsPage";
import BlocksPage from "./pages/blocks/BlocksPage";
import EventsPage from "./pages/events/EventsPage";
import EventEditPage from "./pages/events/EventEditPage";
import UserInfoPage from "./pages/UserInfoPage";
import EventCreatePage from "./pages/events/EventCreatePage";
import MailsEditPage from "./pages/mails/MailsEditPage";
import BlockEditPage from "./pages/blocks/BlockEditPage";
import Account from "./pages/auth/Account";
import ExportsPage from "./pages/mails/ExportsPage";

const routes = (
    <Routes>
        <Route path="/" element={<DashboardLayout />}>
            <Route index element={<DashboardPage />} />
            {/* Mails routes */}
            <Route path="mails">
                <Route index={true} element={<MailsPage />} />
                <Route path="create" element={<MailsEditPage />}/>
                <Route path="exports" element={<ExportsPage />} />
                <Route path=":mailId/edit" element={<MailsEditPage />} />
            </Route>
            {/* Blocks routes */}
            <Route path="blocks">
                <Route index={true} element={<BlocksPage />} />
                <Route path="create" element={<BlockEditPage />} />
                <Route path=":blockId/edit" element={<BlockEditPage />} />
            </Route>
            <Route path="events">
                <Route index={true} element={<EventsPage />} />
                <Route path=":eventId/edit" element={<EventEditPage />} />
                <Route path="create" element={<EventEditPage />} />
            </Route>
            <Route path="admin">
                <Route index={true} element={<AdminPage />} />
                <Route path="users/create" element={<UserInfoPage />} />
                <Route path="users/:userId/edit" element={<UserInfoPage />} />
            </Route>
            <Route path="account" element={<Account />}/>
        </Route>
        <Route path="/login" element={<Login />} />
    </Routes>
);

export default routes;