export default class APIService {
    constructor() {
        this.token = window.localStorage.getItem('token');
        this.baseUrl = window.REACT_APP_APIMAILING_BASE_URL;
    }

    withToken(token) {
        this.token = token;
        return this;
    }

    get(endpoint, queryParams = {}) {
        const queryString = Object.entries(queryParams)
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join('&');
        const url = queryString ? `${this.baseUrl}${endpoint}?${queryString}` : `${this.baseUrl}${endpoint}`;
        return fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/ld+json',
                'Origin': window.location.origin,
                'Authorization': `Bearer ${this.token}`
            },
        });
    }

    post(endpoint, data) {
        const url = `${this.baseUrl}${endpoint}`;
        const clientId = this.user?.consumer?.clientId ?? ""
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/ld+json',
                'Origin': window.location.origin,
                'Authorization': `Bearer ${this.token}`,
            },
            body: JSON.stringify(data),
        });
    }

     put(endpoint, data) {
        const url = `${this.baseUrl}${endpoint}`;

        return fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Origin': window.location.origin,
                'Authorization': `Bearer ${this.token}`
            },
            body: JSON.stringify(data),
        });
    }

    patch(endpoint, data) {
        const url = `${this.baseUrl}${endpoint}`;
        return fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/merge-patch+json',
                'Origin': window.location.origin,
                'Authorization': `Bearer ${this.token}`
            },
            body: JSON.stringify(data),
        });
    }

     delete(endpoint) {
        const url = `${this.baseUrl}${endpoint}`;
        return fetch(url, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${this.token}`,
                'Origin': window.location.origin,
                'Accept': 'application/ld+json',
            },
        })
    }
}
