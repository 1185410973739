import React, {Fragment, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

const Alert = ({ message, type, show }) => {

    const alertContent = (
        <Fragment>
            <span>
                <FontAwesomeIcon
                    icon={faExclamationCircle}
                    className="hover:cursor-pointer mr-2"
                />
            </span>
            <span>{message}</span>
        </Fragment>
    );

    if(!show){
        return null;
    }

    if(type === 'success'){
        return (
            <div className="flex px-4 py-2 my-4 border rounded bg-green-100 border-green-300">
                {alertContent}
            </div>
        )
    }else if (type === 'error'){
        return (
            <div className="flex px-4 py-2 my-4 border rounded bg-red-100 border-red-300">
                {alertContent}
            </div>
        );
    }else if(type === 'warning'){
        return (
            <div className="flex px-4 py-2 my-4 border rounded bg-yellow-100 border-yellow-300">
                {alertContent}
            </div>
        );
    }
}

Alert.defaultProps = {
    type: 'success',
    message: ''
}

export default Alert;