export const getTokenExtraInfo = token => {
    if(token.length > 0){
        let payloadBase64 = token.split('.')[1]
        return JSON.parse(atob(payloadBase64))
    }
    return false
}

export const getHighestRole = (roles = []) => {
    if(roles.includes('ROLE_ADMIN')){
        return 'ROLE_ADMIN'
    }else if(roles.includes('ROLE_EDITOR')){
        return 'ROLE_EDITOR'
    }
    return 'ROLE_USER'
}

export const ROLES = {
    'ROLE_ADMIN': 'Administrateur',
    'ROLE_EDITOR': 'Éditeur',
    'ROLE_USER': 'Membre'
}

export const ROLES_COLOR = {
    ROLE_ADMIN: "#FFCE00",
    ROLE_EDITOR: "#50F0C7",
    ROLE_USER: "#C7FAED",
}