import SoanLogoLoader from '../assets/images/loader_logo.gif';

const sizes = {
    'loader': 'w-8',
    'extra-small': 'w-20',
    'small': 'w-52',
    'normal': 'w-64',
    'large': 'w-72',
}

const SoanLoader = ({show, className, size}) => {
    const loaderSize = sizes[size] || sizes['normal'];
    const loaderShowClasses = `transition-opacity duration-500 ${show ? 'opacity-100' : 'opacity-0'}`;
    return show ? (
        <div className={`flex justify-center items-center ${loaderShowClasses} ${className}`} id="soan-loader">
            <img src={ SoanLogoLoader } className={loaderSize} alt="Soan Logo Loader" />
        </div>
    ) : null
}


SoanLoader.defaultProps = {
    show: false,
    className: '',
    size: 'small'
}

export default SoanLoader;