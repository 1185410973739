import React, {Fragment, useState} from "react";
import {useLocation} from "react-router-dom";
import {Accordion, AccordionDetails, AccordionSummary, Button} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faChevronDown} from "@fortawesome/free-solid-svg-icons";
import APIService from "../../utils/APIService";
import Modal, {ModalBody, ModalFooter, ModalHeader, ModalTransition} from "@atlaskit/modal-dialog";

const ENVIRONMENTS = {
    dev: "Développement",
    test: "Staging",
    prod: "Production"
}

const ExportsPage = props => {
    const api = new APIService();
    const {state} = useLocation();
    const mails = state.selectedMails || [];
    const [env, setEnv] = useState("dev");
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const displayFormattedDate = date => {
        const d = new Date(date);
        const datePadded = (d.getDate() + "").padStart(2, "0");
        const monthPadded = (d.getMonth() + 1 + "").padStart(2, "0");
        return `${datePadded}/${monthPadded}/${d.getFullYear()} à ${d.getHours()}:${d.getMinutes()}`;
    }

    const exportMails = () => {
        const req = api.post('/export', {
            'env': env,
            'mails': mails
        });

        req
            .then(res => {})
            .catch(err => {
                console.error(err);
            });
    }

    return (
        <Fragment>
            <ModalTransition>
                {
                    showConfirmModal && (
                        <Modal onClose={ () => { setShowConfirmModal(false) }}>
                            <ModalHeader>
                                <h1 className="poppins-font">Confirmer l'export des modèles de mail</h1>
                            </ModalHeader>
                            <ModalBody>
                                <p>Vous êtes sur le point d'exporter <b>{mails.length}</b> modèles de mail vers <b>{ENVIRONMENTS[env]}</b></p>
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={() => { setShowConfirmModal(false) }}>Cancel</Button>
                                <Button
                                    onClick={() =>{ exportMails() } }
                                    variant="contained">Exporter</Button>
                            </ModalFooter>
                        </Modal>
                    )
                }
            </ModalTransition>
            <section>
                <header>
                    <h1 className="poppins-font mb-4 text-2xl">Exporter les modèles de mail</h1>
                    <p>Cet page vous permet d'exporter des modèles de mail <br/> vers d'autres environnement.</p>
                </header>
                <div className="flex my-20">
                    <div className="w-2/3">
                        <p className="mb-3"><b>{mails.length}</b> modèles de mail ont été sélectionnés pour être
                            exportés</p>
                        {mails.map((mail) => {
                            return (
                                <Accordion key={mail.id}>
                                    <AccordionSummary
                                        expandIcon={<FontAwesomeIcon icon={faChevronDown}/>}
                                        className="border border-b-gray-400"
                                    >
                                        <p><b>Modèle</b>: {mail.name}</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p className="font-bold">Description:</p>
                                        <p>{mail.description}</p>
                                        <p className="font-bold mt-5">Event:</p>
                                        <p>{mail?.event?.name}</p>
                                        <p className="font-bold mt-5">Conditions:</p>
                                        <ul>
                                            {Object.keys(mail?.conditions || {}).map((key, index) => (
                                                <li className="list-disc ml-8" key={index}>{key}: {
                                                    typeof mail.conditions[key] == "boolean"
                                                        ? mail.conditions[key] ? 'true' : 'false'
                                                        : mail.conditions[key]}
                                                </li>
                                            ))}
                                        </ul>
                                        <p className="font-bold mt-5">Date de création:</p>
                                        <p>{displayFormattedDate(mail?.createdAt || "")}</p>
                                        <p className="font-bold mt-5">Date de mise à jour:</p>
                                        <p>{displayFormattedDate(mail?.modifiedAt || "")}</p>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                    </div>
                    <div className="w-1/3 px-5">
                        <p className="mb-3">Exporter vers:</p>
                        <select
                            onChange={(e) => {
                                setEnv(e.target.value)
                            }}
                            className="w-full bg-white py-2 px-4 rounded border border-gray-400 mb-5">
                            <option value="dev">Développement</option>
                            <option value="test">Staging</option>
                            <option value="prod">Production</option>
                        </select>
                        <Button
                            onClick={() => {
                                setShowConfirmModal(true)
                            }}
                            variant="contained">Exporter</Button>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default ExportsPage;