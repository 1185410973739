import React, {Fragment, useContext, useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faExclamationCircle, faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import ReactHookInputField from "./ReactHookInputField";
import {getHighestRole} from "../../utils/utils";
import Alert from "../Alert";
import {AuthContext} from "../../contexts/AuthContext";


const ROLES = {
    'MEMBER': 'ROLE_USER',
    'EDITOR': 'ROLE_EDITOR',
    'ADMIN': 'ROLE_ADMIN'
}

const UserForm = ({ save, user, alertData }) => {
    const { user: currentUser } = useContext(AuthContext);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [alert, setAlert] = useState(null);

    const schema = yup.object({
        firstName: yup.string().trim().required('Le prénom est requis'),
        lastName: yup.string().trim().required('Le nom de famille est requis'),
        email: yup.string().email('Veuillez fournir une adresse e-mail valide').required('L\'adresse e-mail est requise'),
        role: yup.string().oneOf(Object.values(ROLES), 'Veuillez sélectionner un rôle'),
        password: yup.string().optional(),
        confirmPassword: yup.string().equals([yup.ref('password')], 'Les mots de passe ne correspondent pas').optional(),
    })

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema)
    });


    useEffect(() => {
        reset({
            id: user?.id || '',
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            email: user?.email || '',
            role: getHighestRole(user?.roles || [])
        })
    }, [user])

    useEffect(() => {
        setAlert(alertData)
    }, [alertData])

    const onSubmit = (data, event) => {
        save(
            data,
            event.nativeEvent.submitter.name === 'saveAndCreate'
        );
    }

    return (
        <form
            id="user-form"
            className="px-5"
            onSubmit={ handleSubmit(onSubmit) }
        >
            <div className="my-10 mx-10 shadow p-10">
                <h1 className="text-center font-bold poppins-font text-2xl mb-10">Informations</h1>
                <Alert type={alert?.type} message={alert?.message} show={ alert?.type !== undefined }/>
                <ReactHookInputField
                    name="lastName"
                    label="Nom"
                    placeholder="Nom de famille"
                    type="text"
                    register={register}
                    error={errors?.lastName} />

                <ReactHookInputField
                    name="firstName"
                    label="Prénom"
                    placeholder="Prénom"
                    type="text"
                    register={register}
                    error={errors?.firstName} />

                <ReactHookInputField
                    name="email"
                    label="Adresse e-mail"
                    placeholder="Adresse e-mail"
                    type="email"
                    register={register}
                    error={errors?.email} />
                {
                    currentUser.roles?.includes('ROLE_ADMIN') &&
                    (
                        <div className="input-field flex flex-col mb-6">
                            <label htmlFor="role" className="poppins-font">Gestion des droits</label>
                            <select
                                id="role"
                                {...register("role")}
                                className="mt-2 border rounded py-2 px-4 outline-none border-gray-200 focus:border-gray-400 bg-white">
                                <option value="ROLE_USER">Membre</option>
                                <option value="ROLE_EDITOR">Editeur</option>
                                <option value="ROLE_ADMIN">Administrateur</option>
                            </select>
                            <p className="text-gray-600 text-sm my-2">Choisissez les actions qui pourront être effectuées par cet utilisateur</p>
                        </div>
                    )
                }

            </div>
            <div className="mx-10 shadow p-10">
                <h1 className="text-center font-bold poppins-font text-2xl mb-10">Mot de passe</h1>
                <ReactHookInputField
                    name="password"
                    label="Mot de passe"
                    placeholder="Mot de passe"
                    type={ showPassword ? "text" : "password"}
                    register={register}
                    error={errors?.password}
                    customRightIcon={
                        <FontAwesomeIcon
                            icon={ !showPassword ? faEye : faEyeSlash}
                            className="absolute right-4 hover:cursor-pointer"
                            onClick={ () => setShowPassword(!showPassword) } />
                    } />

                <ReactHookInputField
                    name="confirmPassword"
                    label="Confirmer le mot de passe"
                    placeholder="Confirmer le mot de passe"
                    type={ showConfirmPassword ? "text" : "password"}
                    register={register}
                    error={errors?.confirmPassword}
                    customRightIcon={
                        <FontAwesomeIcon
                            icon={ !showConfirmPassword ? faEye : faEyeSlash}
                            className="absolute right-4 hover:cursor-pointer"
                            onClick={ () => setShowConfirmPassword(!showConfirmPassword) } />
                    } />
            </div>
            <div className="flex justify-evenly mt-16 mb-10">
                {
                    !user?.id ? (
                        <Fragment>
                            <button
                                {...register("saveAndCreate")}
                                className="px-4 py-2 border border-[#7000FF] rounded-lg mr-2 text-[#7000FF] font-semibold">Enregistrer et créer un autre utilisateur</button>
                            <button
                                {...register("save")}
                                className="px-4 py-2  border rounded-lg bg-[#6500E5] text-white font-semibold">Enregistrer le nouvel utilisateur</button>
                        </Fragment>
                    ): (
                        <button
                            {...register("save")}
                            className="px-4 py-2  border rounded-lg bg-[#6500E5] text-white font-semibold">
                            Enregistrer les modifications
                        </button>
                    )
                }
            </div>
        </form>
    )
}

UserForm.defaultProps = {
    user: {}
}

export default UserForm;