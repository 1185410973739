import React, {useContext, useEffect, useState} from "react";
import APIService from "../../utils/APIService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAngleUp,
    faAngleDown,
    faPen,
    faPlus,
    faSearch,
    faTrash,
    faEdit,
    faEye, faSort, faArrowDownZA, faArrowDownAZ
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import CustomModalDialog from "../../components/Modal";
import Alert from "../../components/Alert";
import {Pagination} from "@mui/material";
import {parseDate} from "../../utils/date";
import Clamp from "react-multiline-clamp";
import {AuthContext} from "../../contexts/AuthContext";

const BlocksPage = props => {
    const {user} = useContext(AuthContext);
    const [orderBy, setOrderBy] = useState('modifiedAt');
    const [order, setOrder] = useState('DESC');
    const [blocks, setBlocks] = useState([]);
    const [selectedBlocks, setSelectedBlocks] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [alert, setAlert] = useState(null);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [query, setQuery] = useState('');

    const api = new APIService();

    useEffect(() => {
        api.get(`/blocks?page=${page}&orSearch[name]=${query}&orSearch[description]=${query}&order[${orderBy}]=${order}`)
            .then(res => res.json())
            .then(data => {
                let hydraMembers = data['hydra:member'] || [];
                setBlocks(hydraMembers);
                setTotalPages(Math.ceil((data['hydra:totalItems'] || 0) / 10));
            })
            .catch(err => {
                setAlert({
                    type: 'error',
                    message: 'Une erreur est survenue lors du chargement des blocs'
                });
                console.log(err)
            });
    }, [page, query, orderBy, order]);

    const deleteBlocks = () => {
        for(const blockId of selectedBlocks){
            api.delete(`/blocks/${blockId}`)
                .then(res => {
                    if(res.status === 204){
                        setBlocks(blocks.filter(block => block.id !== blockId))
                        setSelectedBlocks(selectedBlocks.filter(id => id !== blockId))
                        setAlert({
                            type: 'success',
                            message: 'Bloc supprimé avec succès'
                        });
                    }else if(res.status === 404){
                        setAlert({
                            type: 'error',
                            message: 'Bloc introuvable'
                        });
                    }else{
                        setAlert({
                            type: 'error',
                            message: 'Une erreur est survenue lors de la suppression du bloc'
                        });
                        console.log(res)
                    }
                })
                .catch(err => {
                    setAlert({
                        type: 'error',
                        message: 'Une erreur est survenue lors de la suppression du bloc'
                    })
                    console.log(err)
                })
        }
    }

    const getOrderIcon = fieldName => {
        if(fieldName !== orderBy){
            return faSort;
        }
        if(fieldName === orderBy && 'DESC' === order){
            return faArrowDownZA;
        }
        return faArrowDownAZ;
    }

    return (
        <div id="blocks" className="mb-10">
            <CustomModalDialog
                isOpen={showDeleteModal}
                headerTitle="Confirmation de la suppression"
                bodyContent="Êtes-vous sûr de vouloir supprimer les blocs sélectionnés ?"
                onCancel={() => { setShowDeleteModal(false) }}
                onConfirm={() => {
                    setShowDeleteModal(false);
                    deleteBlocks();
                }}/>
            <section>
                <header>
                    <h1 className="poppins-font mb-4 text-2xl">Blocs</h1>
                    <p>Cet page vous permet de voir, créer, modifier ou supprimer <br/> des blocs.</p>
                </header>
                <div className="mt-10 flex justify-between">
                    <div className="flex h-10">
                        {
                            user?.roles.includes('ROLE_ADMIN') ? (
                                <Link to="/blocks/create" className="bg-[#00E5DA] font-bold py-2 px-8 rounded">
                                    <FontAwesomeIcon icon={faPlus} className="mr-4" />
                                    <span>Ajouter un nouveau bloc</span>
                                </Link>
                            ): <div className="w-72"/>
                        }
                    </div>
                    <div className="ml-2 relative flex items-center w-32 basis-1/3">
                        <input
                            type="text"
                            placeholder="Filtrer par nom"
                            onChange={(e) => {
                                const queryText = e.target.value;
                                if(queryText?.trim().length > 2){
                                    setQuery(e.target.value)
                                }else{
                                    setQuery('')
                                }
                            }}
                            className="w-full h-full rounded shadow border pl-4 pr-9 focus:border-gray-300 focus:outline-none"/>
                        <FontAwesomeIcon
                            icon={faSearch}
                            className="absolute text-gray-500 hover:text-gray-700 hover:cursor-pointer right-4"/>
                    </div>
                    <div className="flex items-center">
                        {
                            user?.roles.includes('ROLE_ADMIN') && (
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    onClick={() => {
                                        setShowDeleteModal(true);
                                    }}
                                    className={selectedBlocks?.length > 0 ? 'hover:cursor-pointer text-black' : 'text-gray-400'} />
                            )
                        }
                    </div>
                </div>
            </section>
            <section>
                <Alert type={alert?.type} message={alert?.message} show={alert !== null} />
                <table className="w-full mt-10 border-separate border-spacing-y-2 table-fixed">
                    <thead>
                        <tr className="text-left poppins-font">
                            <th className="w-12"></th>
                            <th className="w-72">
                                <span>Nom</span>
                            </th>
                            <th className="w-1/3">Description</th>
                            <th className="w-52">
                                <span>Date de création</span>
                                <FontAwesomeIcon
                                    icon={getOrderIcon('createdAt')}
                                    className="ml-2 hover:cursor-pointer"
                                    onClick={() => {
                                        setOrderBy('createdAt')
                                        setOrder(order === 'DESC' ? 'ASC' : 'DESC')
                                    }}
                                />
                            </th>
                            <th className="w-60">
                                <span>Dernière modification</span>
                                <FontAwesomeIcon
                                    icon={getOrderIcon('modifiedAt')}
                                    className="ml-2 hover:cursor-pointer"
                                    onClick={() => {
                                        setOrderBy('modifiedAt')
                                        setOrder(order === 'DESC' ? 'ASC' : 'DESC')
                                    }}
                                />
                            </th>
                            <th>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        blocks && blocks.map(block => (
                            <tr key={block.id} className="bg-white shadow">
                                <td className="p-4">
                                    <input type="checkbox" onChange={(e) => {
                                        if(e.target.checked) {
                                            setSelectedBlocks([...selectedBlocks, block.id]);
                                        }else {
                                            setSelectedBlocks(selectedBlocks.filter(id => id !== block.id));
                                        }
                                    }}/>
                                </td>
                                <td>
                                    <Link
                                        className="font-bold"
                                        to={`/blocks/${block.id}/edit`}>{block.name}</Link>
                                    <p className="text-xs text-gray-600">
                                        Crée par <span className="font-bold">{ block?.createdBy?.firstName || '' }</span>
                                    </p>
                                </td>
                                <td className="py-2">
                                    <div className="flex items-center pr-4">
                                        <Clamp
                                            showLines={2}
                                            withToggle={true}
                                            showMoreElement={({toggle}) => (
                                                <FontAwesomeIcon
                                                    icon={faAngleDown}
                                                    onClick={toggle}
                                                    className="text-gray-400 hover:text-black hover:cursor-pointer" />
                                            )}
                                            showLessElement={({toggle}) => (
                                                <FontAwesomeIcon
                                                    icon={faAngleUp}
                                                    onClick={toggle}
                                                    className="text-gray-400 hover:text-black hover:cursor-pointer" />
                                            )}
                                        >
                                            <span>{block?.description ?? ''}</span>
                                        </Clamp>
                                    </div>
                                </td>
                                <td>
                                    <span>{ parseDate(block.createdAt) || '-'}</span>
                                </td>
                                <td>
                                    <span>{ parseDate(block.modifiedAt)  || '-'}</span>
                                </td>
                                <td className="p-4">
                                    <Link to={`/blocks/${block.id}/edit`}>
                                        <FontAwesomeIcon
                                            icon={
                                                (user?.roles.includes('ROLE_ADMIN') || user?.roles.includes('ROLE_EDITOR')) ? faPen : faEye
                                            }
                                            className="text-gray-400 hover:text-black hover:cursor-pointer"
                                        />
                                    </Link>
                                </td>
                            </tr>
                        ))

                    }
                    </tbody>
                </table>
            </section>
            <section className="flex mt-5 justify-center">
                <Pagination
                    count={totalPages}
                    shape="rounded"
                    variant="outlined"
                    onChange={(e, page) => setPage(page)}/>
            </section>
        </div>
    );
}

export default BlocksPage;