import {Link, useLocation} from "react-router-dom";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCircleUser, faChevronDown } from "@fortawesome/free-solid-svg-icons";

import soanLogo from '../../assets/images/logo-noir.svg';
import {getHighestRole} from "../../utils/utils";

const Header = ({ user, logout }) => {
    const {pathname} = useLocation();
    const navLinks = [
        {
            'path': "/",
            'title': "Dashboard",
            'color': '#000000',
            'roles': ['ROLE_ADMIN', 'ROLE_EDITOR', 'ROLE_USER']
        },
        {
            'path': "/mails",
            'title': "Modèles de mails",
            'color': '#00E5DA',
            'roles': ['ROLE_ADMIN', 'ROLE_EDITOR', 'ROLE_USER']
        },
        {
            'path': "/blocks",
            'title': "Blocs",
            'color': "#00A4E5",
            'roles': ['ROLE_ADMIN', 'ROLE_EDITOR', 'ROLE_USER']
        },
        {
            'path': "/events",
            'title': "Évènements",
            'color': "#00CAE5",
            'roles': ['ROLE_ADMIN', 'ROLE_EDITOR', 'ROLE_USER']
        },
        {
            'path': "/admin",
            'title': "Administration",
            'color': "#FFCE00",
            'roles': ['ROLE_ADMIN']
        }
    ];

    const isNavActive = (path) => {
        return (pathname.startsWith(path) && path !== '/') || (path === pathname);
    }

    return(
        <nav className="flex justify-between pt-10 mb-16">
            <div className="flex">
                <Link to="/">
                    <img id="soan-logo" src={soanLogo} alt="Logo SOAN" className="flex-shrink-0" />
                </Link>
            </div>
            <ul className="flex items-center space-x-12 text-lg poppins-font col-span-3">
                { navLinks.map((link, index) =>
                    link.roles.includes(getHighestRole(user.roles)) && (
                        <li className="nav-link" key={index}>
                            <Link to={link.path}>{link.title}</Link>
                            { isNavActive(link.path) ?
                                <div
                                    className="select-nav-link"
                                    style={{ background: link.color }}>
                                </div> : null
                            }
                        </li>
                        )
                )}
            </ul>
            <div className="cursor-pointer relative">
                <div className="relative group p-2">
                    <FontAwesomeIcon icon={faCircleUser} size={"xl"}/>
                    <span className="mx-3">{user.firstName}</span>
                    <div className="w-36 hidden left-0 right-0 absolute z-10 bg-white p-2 rounded-md shadow-md group-hover:block mt-1 text-center divide-y">
                        <div className="p-2">
                            <Link to="/account">Mon compte</Link>
                        </div>
                        <div className="p-2">
                            <button onClick={ () => logout() }>Se déconnecter</button>
                        </div>
                    </div>
                </div>
            </div>



        </nav>
    )
};

export default Header;