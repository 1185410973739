import React, {useContext} from "react";
import { AuthContext } from "../../contexts/AuthContext";
import {Link, Navigate, useLocation} from "react-router-dom";
import LoginForm from "../../components/form/LoginForm";

// soan logo
import soanLogo from '../../assets/images/logo-noir.svg';
import {Alert, AlertTitle} from "@mui/material";

const Login = (props) => {
    const {isLoggedIn, login, error, cancelError, user} = useContext(AuthContext);
    const {search, state} = useLocation()
    const params = new URLSearchParams(search)
    let redirectUrl = params.get('from') || '/'

    if(isLoggedIn){
        if(redirectUrl.startsWith('/admin') && !user?.roles?.includes('ROLE_ADMIN')){
            redirectUrl = '/'
        }
        return <Navigate to={redirectUrl} />
    }

    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <div id="loginForm" className="w-3/12 max-w-full min-w-fit m-2">
                <img src={soanLogo} alt="Logo Soan" id="soan-logo" className="mx-auto mb-6"/>
                <h3 className="text-center font-bold text-2xl text-gray-500 poppins-font">Service mail</h3>
                <h2 className="text-center font-bold text-4xl mb-7 poppins-font">Connexion</h2>
                <div className="text-center text-sm text-gray-600">
                    <p>Pas encore de compte, ou mot de passe oublié ?</p>
                    <p>
                        <Link
                            to="https://www.notion.so/Interface-mailing-08b31071d4c54a6bb01b94785615e0df"
                            target="_blank"
                            className="underline">Adressez-vous à un administrateur</Link>
                    </p>
                </div>
                { state && state.tokenExpired && (
                        <Alert severity="error" className="my-5">
                            <AlertTitle>Erreur</AlertTitle>
                            Votre session a expirée, veuillez vous reconnecter.
                        </Alert>
                    )
                }
                <LoginForm login={login} error={error} cancelError={cancelError}/>
            </div>
        </div>
    );
}

export default Login;