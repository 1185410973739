export const formattedDate = dateString => {
    let newDate = new Date(dateString);
    let dayOfMonth = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${dayOfMonth}-${month}-${year}`;
}

export const parseDate = dateString => {
    let newDate = new Date(dateString);
    let dayOfMonth = newDate.getDate().toString().padStart(2, '0');
    let month = (newDate.getMonth()+1).toString().padStart(2, '0');;
    let year = newDate.getFullYear().toString().padStart(2, '0');
    let hours = newDate.getHours().toString().padStart(2, '0');
    let minutes = newDate.getMinutes().toString().padStart(2, '0');
    let seconds = newDate.getSeconds().toString().padStart(2, '0');

    return `${dayOfMonth}/${month}/${year}`;
}